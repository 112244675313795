import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portfolio-slider',
  templateUrl: './portfolio-slider.component.html',
  styleUrls: ['./portfolio-slider.component.scss']
})
export class PortfolioSliderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
