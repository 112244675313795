<div class="service-title service-title-bg bg-3 sanitazion-and-desinfection">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h1>IGIENIZARE SI DEZINFECTIE SPATII</h1>
                    <ul>
                        <li>
                            <a routerLink="/">Acasa</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Igenizare si dezinfectie spatii
                        </li>
                        
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-details-area main-service-area pt-100 services-details-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <img src="assets/img/service/curatenie cabinete medicale saloane infrumusetare.jpg"
                    alt="curatenie cabinete medicale saloane infrumusetare">
                <div class="service-details-post">
                    
                    <p>Sa te simti in siguranta este una dintre cele mai importante necesitati umane. Insa atunci cand
                        batalia este data cu inamicii care nu pot fi vazuti cu ochiul liber, situatia devine putin mai
                        complexa.</p>
                    <p>Procedurile de igienizare si de dezinfectie necesita o atentie deosebita pentru ca:</p>
                    <ul>
                        <li>
                            - Facute in mod incorect, nu se ajunge la rezultatul dorit
                        </li>
                        <li>- Facute cu solutii agresive, pot cauza reactii adverse neplacute</li>
                    </ul>

                    <h3>Servicii de igienizare prin nebulizare</h3>
                    <p>O vorba din popor spune ca “e mai bine sa previi decat sa tratezi”, iar noi nu putem decat sa
                        aprobam aceasta zicala. </p>
                    <p>Atunci cand este vorba despre sanatatea noastra si a celor din jur, nu exista loc de
                        compromisuri.</p>
                        <video  controls>
                            <source src="/assets/allsteam igienizare dezinfectie nebulizare (Converted).mov" type="video/mp4">
                            
                          Your browser does not support the video tag.
                          </video>
                    <h3>Ce este igienizarea prin nebulizare?</h3>

                    <p>In linii mari, procedeul de nebulizare reprezinta igienizarea unui spatiu prin pulverizarea unei
                        solutii dezinfectante, in particule extrem de fine, asemanator unei cete reci.
                        Echipa noastra realizeaza aceasta operatiune prin intermediul unui aparat portabil, de mici
                        dimensiuni.
                    </p>

                    <h3>Ce avantaje prezinta igienizarea prin nebulizare?</h3>
                    <ul>
                        <li>
                            <i class="icofont-check-circled"></i>Este mai rapid decat o igienizare clasica datorita
                            pulverizarii solutiei sub forma de ceata, care se raspandeste cu usurinta pe toata suprafata
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>Patrunde chiar si in cele mai greu accesibile unghiuri
                            ale unui spatiu - guri de ventilatie, aparate de aer conditionat - ceata rece dezinfectanta
                            facandu-si rapid loc</li>
                        <li>
                            <i class="icofont-check-circled"></i>Este potrivit pentru spatiile cu dimensiuni reduse -
                            lifturi, toalete - datorita flexibilitatii aparatului portabil de nebulizare</li>
                        <li>
                            <i class="icofont-check-circled"></i>Este eficient in spatiile de mari dimensiuni - saloane
                            de evenimente, cladiri de birouri, terase, parcari</li>
                        <li>
                            <i class="icofont-check-circled"></i>Concomitent cu igienizarea si dezinfectarea, se
                            realizeaza si sterilizarea si decontaminarea aerului</li>
                    </ul>
                    <h3>Servicii de dezinfectie Brasov</h3>
                    <p>Zonele frecvent atinse, mai ales in spatiile cu trafic mare de persoane, necesita o dezinfectie
                        cu solutii eficiente, pentru eliminarea germenilor si prevenirea imbolnavirilor.
                    </p>
                    <p>Pentru buna desfasurare a activitatilor, recomandam periodic:</p>
                    <ul>

                        <li>
                            <i class="icofont-check-circled"></i>Dezinfectie pentru birouri</li>
                        <li>
                            <i class="icofont-check-circled"></i>Dezinfectie pentru restaurante, cafenele, baruri si
                            hoteluri</li>
                        <li>
                            <i class="icofont-check-circled"></i>Dezinfectie pentru sedii de firme</li>
                        <li>
                            <i class="icofont-check-circled"></i>Dezinfectie pentru locuinte personale</li>
                        <li>
                            <i class="icofont-check-circled"></i>Dezinfectie pentru cabinete medicale si stomatologii
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>Dezinfectie pentru saloane de infrumusetare</li>

                    </ul>
                    <p>Serviciile noastre sunt potrivite atat persoanelor juridice care desfasoara activitati comerciale, dar si celor fizice, care vor sa beneficieze de aceste servicii de calitate si profesionale.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 main-service-area blog-side-bar">
                <app-sidebar></app-sidebar>
            </div>
        </div>
    </div>
</div>
