<div class="service-title service-title-bg bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h1>CURATENIE HOTELURI, RESTAURANTE, CAFENELE IN BRASOV</h1>
                    <ul>
                        <li>
                            <a routerLink="/">Acasa</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            <a routerLink="/firma-curatenie-brasov">Servicii de curatenie</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Curatenie hoteluri, restaurante, cafenele in Brasov
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-details-area main-service-area pt-100 services-details-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <img src="assets/img/service/curatenie horeca brasov.jpg" alt="curatenie horeca brasov">
                <div class="service-details-post">
                    
                    <p>Zona de activitate HoReCa prezinta acum mai multe provocari ca niciodata.
                        Mai mult ca oricand, clientii au nevoie sa se simta in siguranta in locurile pe care le
                        frecventaza cu scopul de a se relaxa si de a servi o cafea buna si o mancare delicioasa in
                        preajma celor apropiati.

                    </p>


                    <h3>AllSteam - echipa ta de curatenie
                        <span>de incredere</span> pentru zona HoReCa</h3>
                    <p>Curatenia si igiena in zonele de producere a alimentelor trebuie tratata cu maxima seriozitate
                        pentru a oferi clientilor toata siguranta si tot confortul de care au nevoie pentru a se simti
                        bine in timpul pe care il petrec in locatia ta, indiferent ca este vorba despre un restaurant, o
                        cafenea sau un bar sau chiar o locatie de cazare.

                    </p>
                    <h3>Ce putem face pentru afacerea ta din zona HoReCa?
                    </h3>
                    <ul>
                        <li>
                            <i class="icofont-check-circled"></i>Curatare si dezinfectare restaurante, cafenele si
                            baruri conform standardelor impuse</li>
                        <li>
                            <i class="icofont-check-circled"></i>Curatare, dezinfectare si igienizare bucatarii, toalete
                            si spatii de cazare</li>
                        <li>
                            <i class="icofont-check-circled"></i>Aspirare si spalare pardoseli, mochete & covoare,
                            tapiterii & scaune</li>
                        <li>
                            <i class="icofont-check-circled"></i>Spalare geamuri, obiecte de iluminat</li>
                        <li>
                            <i class="icofont-check-circled"></i>Curatarea casetelor luminoase</li>
                        <li>
                            <i class="icofont-check-circled"></i>Evacuarea gunoiului si a resturilor menajere</li>
                        <li>
                            <i class="icofont-check-circled"></i>Stergerea prafului de pe mobilier cu solutie
                            antistatica</li>


                    </ul>

                    <p>
                        <strong>In plus, putem sa mai facem si:
                        </strong>
                    </p>

                    <ul>
                        <li>
                            <i class="icofont-check-circled"></i>Curatarea profesionala canapele, fotolii, scaune si
                            saltele</li>
                        <li>
                            <i class="icofont-check-circled"></i>Dezinfectare profesionala a zonelor frecvent atinse si
                            de tranzit</li>
                        <li>
                            <i class="icofont-check-circled"></i>Dezinfectare spatii ample prin procesul de nebulizare
                            (ceata rece)</li>
                        <li>
                            <i class="icofont-check-circled"></i>Curatare mecanizata a pardoselilor dure si a
                            copertinelor</li>
                        <li>
                            <i class="icofont-check-circled"></i>Curatare in profunzime a utilajelor de productie in
                            bucatariile HoReCa</li>
                    </ul>
                    <h3>Curatenia - cartea de vizita a restaurantelor, a cafenelelor si a barurilor
                    </h3>

                    <p>O locatie curata iti va face clientii multumiti si ii vor face sa se reintoarca intotdeauna, cu placere. 
                        Echipa noastra se asigura ca standardele de curatenie se vor situa intotdeauna la cote maxime, indiferent de tipul afacerii pe care o detii.
                        Ne deplasam oriunde in Brasov si in zonele limitrofe si-ti oferim solutii personalizate de curatenie, adaptate afacerii tale.
                        
                    </p>

                 
                </div>

                
            </div>

            <div class="col-lg-4 main-service-area blog-side-bar">
                <app-sidebar></app-sidebar>
            </div>
        </div>
    </div>
</div>
