<div class="service-sidebar">

    <div class="service-list">
        <p> <a routerLink="/igienizare-dezinfectie-spatii-brasov" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Igenizare si Dezinfectare</a>
        </p>

        <p>Servicii</p>
        <ul>
            <li><a routerLink="/curatenie-birouri-brasov" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Curatenie Birouri</a></li>
            <li><a routerLink="/curatenie-la-domiciliu-brasov" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Curatenie la Domiciliu</a></li>
            <li><a routerLink="/curatenie-scari-de-bloc-brasov" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Curatenie Scari de Bloc</a></li>
            <li><a routerLink="/curatenie-restaurante-cafenele-baruri-brasov" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Curatenie HoReCa</a></li>
            <li><a routerLink="/curatenie-dupa-constructor-renovare-brasov" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Curatenie dupa Constructor</a></li>
            <li><a routerLink="/curatenie-saloane-infrumusetare-cabinete-medicale-brasov" class="nav-link"
                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Curatenie Saloane Medicale</a>
            </li>
            <li><a routerLink="/curatenie-saloane-infrumusetare-cabinete-medicale-brasov" class="nav-link"
                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Curatenie Cabinete
                    Infrumusetare</a></li>


        </ul>
    </div>
    <div class="service-list service-list--contact">
        <ul>
            <li>
                <i class="icofont-phone"></i>
                <a href="tel:+40758116325">0758-116-325</a>
            </li>
            <li>
                <i class="icofont-ui-message"></i>
                <a href="mailto:curatenie@allsteam.ro">curatenie@allsteam.ro</a>
            </li>
        </ul>


    </div>
    <div class="service-list">
        <p>Curatare</p>
        <ul>

            <li><a routerLink="/curatare-canapele-fotolii-scaune-brasov" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Canapele, Fotolii & Saltele</a></li>
            <li><a routerLink="/firma-curatare-podele-pavaje-brasov" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Pardoseala & Pavaje</a></li>
            <li><a routerLink="/firma-curatare-echipamente-horeca-brasov" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Echipamente HoReCa</a></li>
            <li><a routerLink="/curatare-tapiterie-auto-detailing-auto-brasov" class="nav-link"
                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Tapiterie Auto & Detailing</a>
            </li>
            <li><a routerLink="/servicii-speciale-de-curatare-brasov" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Servicii Speciale</a></li>



        </ul>
    </div>
    <div class="discount-text prices">
        <div class="item" *ngIf="router.url.includes('/curatenie-la-domiciliu-brasov')">
            <p>CURATENIE LA DOMICILIU<p>
                    <ul>
                        <li>Garsoniera: 500 RON</li>
                        <li>Apartament 2 camere: 600 RON</li>
                        <li>Apartament 3 camere: 700 RON</li>
                        <li>Apartament 5 camere: 800 RON</li>
                        <li>Vile: 10 RON / mp</li>
                    </ul>


                    <p>IGIENIZARE & DEZINFECTIE</p>
                    <ul>
                        <li>Cu dezinfectant: 1,2 RON / mp
                        </li>
                        <li>Cu nebulizare: 3 RON / mp</li>
                    </ul>


                    <p>CURATENIE DUPA CONSTRUCTOR</p>
                    <ul>
                        <li>Intre 12 RON si 15 RON / mp*</li>
                        <li>*In functie de gradul de murdarie</li>
                    </ul>



                    <p>CURATARE CANAPELE</p>
                    <ul>
                        <li>3 locuri: 200 RON</li>
                        <li>4 locuri: 300 RON</li>
                        <li>Fotolii: 80 RON</li>
                        <li>Scaun tapitat: 25 RON</li>
                    </ul>


                    <p>CURATARE MOCHETA</p>
                    <ul>
                        <li>12 RON / mp</li>
                    </ul>
        </div>
        <div class="item" *ngIf="router.url.includes('/allsteam.ro/curatenie-restaurante-cafenele-baruri-brasov')">
            <p>INTRETINERE HORECA</p>
            <ul>
                <li>Bucatarii: 12 RON / mp</li>
                <li>Lobby: 10 RON / mp</li>
            </ul>



            <p>CURATARE CANAPELE</p>
            <ul>
                <li>3 locuri: 200 RON</li>
                <li>4 locuri: 300 RON</li>
                <li>Fotolii: 80 RON</li>
                <li>Scaun tapitat: 25 RON</li>
            </ul>


            <p>CURATARE MOCHETA</p>
            <ul>
                <li> 12 RON / mp</li>
            </ul>


            <p>IGIENIZARE & DEZINFECTIE</p>
            <ul>
                <li>Cu dezinfectant: 1,2 RON / mp</li>
                <li>Cu nebulizare: 3 RON / mp</li>
            </ul>


            <p>CURATAT ECHIPAMENTE HORECA</p>
            <ul>
                <li>Intre 150 - 250 RON / buc</li>
            </ul>


            <p>CURATAT HOTE PROFESIONALE</p>
            <ul>
                <li>Intre 400 - 800 RON / BUC</li>
            </ul>


            <p>CURATAT MOTOR HOTA</p>
            <ul>
                <li>Intre 600 - 900 RON / buc</li>
            </ul>

            <p>CURATAT TUBULATURA</p>

            <ul>
                <li> Intre 150 - 300 RON</li>
            </ul>


        </div>
       

        <div class="item" *ngIf="router.url.includes('/curatenie-dupa-constructor-renovare-brasov')">
            <p>CURATENIE DUPA CONSTRUCTOR</p>
            <ul>
                <li>Intre 12 RON si 15 RON / mp*</li>
                <li>In functie de gradul de murdarie</li>
            </ul>
        </div>

        <div class="item" *ngIf="router.url.includes('/curatenie-saloane-infrumusetare-cabinete-medicale-brasov')">
            <p>IGIENIZARE & DEZINFECTIE</p>
            <ul>
                <li>Cu dezinfectant: 1,2 RON / mp</li>
                <li>Cu nebulizare: 3 RON / mp</li>
            </ul>


            <p>CURATENIE GENERALA</p>
            <ul>
                <li>10 RON / mp</li>
            </ul>


            <p>CURATENIE DE INTRETINERE</p>
            <ul>
                <li>6 RON / mp</li>
            </ul>


            <p>CURATARE CANAPELE</p>
            <ul>
                <li>3 locuri: 200 RON</li>
                <li>4 locuri: 300 RON</li>
                <li>Fotolii: 80 RON</li>
                <li>Scaun tapitat: 25 RON</li>
            </ul>


        </div>

        <div class="item" *ngIf="router.url.includes('/curatare-canapele-fotolii-scaune-brasov')">
            <p> CURATARE CANAPELE</p>
            <ul>
                <li>3 locuri: 200 RON</li>
                <li>4 locuri: 300 RON</li>
                <li>Fotolii: 80 RON</li>
                <li>Scaun tapitat: 25 RON</li>
            </ul>


        </div>
        <div class="item" *ngIf="router.url.includes('/firma-curatare-echipamente-horeca-brasov')">
            <p>CURATAT ECHIPAMENTE HORECA</p>
            <ul>
                <li>Intre 150 - 250 RON / buc</li>
            </ul>


            <p>CURATAT HOTE PROFESIONALE</p>
            <ul>
                <li>Intre 400 - 800 RON / BUC</li>
            </ul>


            <p>CURATAT MOTOR HOTA</p>
            <ul>
                <li>Intre 600 - 900 RON / buc</li>
            </ul>


            <p>CURATAT TUBULATURA</p>
            <ul>
                <li>Intre 150 - 300 RON</li>
            </ul>
        </div>


        <div class="item" *ngIf="router.url.includes('/curatare-tapiterie-auto-detailing-auto-brasov')">
            <p>CURATARE AUTO</p>
            <ul>

                <li>Intre 350 RON si 550 RON</li>
                <li>Cabina TIR: 500 RON</li>
                <li>AUTOBUZ: 800 RON</li>
                <li>RULOTA: 500 RON</li>
                <li>AVION: 3000 RON</li>

            </ul>
        </div>
        <div class="item" *ngIf="router.url.includes('/servicii-speciale-de-curatare-brasov')">
            <p>SERVICII SPECIALE DE CURATARE</p>
            <ul>
                <li>Preturile se stabilesc in functie de complexitatea lucrarii de curatare.</li>
            </ul>
            
            
        </div>
        <div class="item" *ngIf="router.url.includes('/igienizare-dezinfectie-spatii-brasov')">
            <p>IGIENIZARE & DEZINFECTIE</p>
            <ul>
                <li>Cu dezinfectant: 1,2 RON / mp </li>
                <li>Cu nebulizare: 3 RON / mp</li>
            </ul>
            
            
        </div>
        <div class="discount-shapes"><img src="assets/img/counter/1.png" alt="shape"><img src="assets/img/counter/2.png"
                alt="shape"><img src="assets/img/counter/3.png" alt="shape"><img src="assets/img/counter/4.png"
                alt="shape"><img src="assets/img/counter/5.png" alt="shape"><img src="assets/img/counter/6.png"
                alt="shape"><img src="assets/img/counter/7.png" alt="shape"><img src="assets/img/counter/8.png"
                alt="shape"><img src="assets/img/counter/5.png" alt="shape"></div>
    </div>
</div>
