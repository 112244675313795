import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cleaning-floor',
  templateUrl: './cleaning-floor.component.html',
  styleUrls: ['./cleaning-floor.component.scss']
})
export class CleaningFloorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
