<div class="team-title team-title-bg">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="team-title-text">
                    <h2>Our Team Members</h2>
                    <ul>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Team Members
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="team-section team-style-two team-style-three team-members pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/1.png" alt="team member">

                        <div class="team-social">                           
                            <a href="#"><i class="icofont-facebook"></i></a>
                            <a href="#"><i class="icofont-twitter"></i></a>                  
                            <a href="#"><i class="icofont-instagram"></i></a>                        
                        </div>
                    </div>
                    <div class="team-text">
                        <h4>john Smith</h4>
                        <p>Head of Cleaner</p>
                    </div>
                </div>
            </div> 
            
            <div class="col-lg-3 col-md-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/2.png" alt="team member">

                        <div class="team-social">                           
                            <a href="#"><i class="icofont-facebook"></i></a>
                            <a href="#"><i class="icofont-twitter"></i></a>                  
                            <a href="#"><i class="icofont-instagram"></i></a>                        
                        </div>
                    </div>
                    <div class="team-text">
                        <h4>Marry</h4>
                        <p>Cleaner</p>
                    </div>
                </div>
            </div> 

            <div class="col-lg-3 col-md-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/3.png" alt="team member">

                        <div class="team-social">                           
                            <a href="#"><i class="icofont-facebook"></i></a>
                            <a href="#"><i class="icofont-twitter"></i></a>                  
                            <a href="#"><i class="icofont-instagram"></i></a>                        
                        </div>
                    </div>
                    <div class="team-text">
                        <h4>john Smith</h4>
                        <p>Office Cleaner</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/4.png" alt="team member">

                        <div class="team-social">                           
                            <a href="#"><i class="icofont-facebook"></i></a>
                            <a href="#"><i class="icofont-twitter"></i></a>                  
                            <a href="#"><i class="icofont-instagram"></i></a>                        
                        </div>
                    </div>
                    <div class="team-text">
                        <h4>Eve</h4>
                        <p>Assistant</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/5.png" alt="team member">

                        <div class="team-social">                           
                            <a href="#"><i class="icofont-facebook"></i></a>
                            <a href="#"><i class="icofont-twitter"></i></a>                  
                            <a href="#"><i class="icofont-instagram"></i></a>                        
                        </div>
                    </div>
                    <div class="team-text">
                        <h4>John Dew</h4>
                        <p>Cleaner</p>
                    </div>
                </div>
            </div> 
            
            <div class="col-lg-3 col-md-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/6.png" alt="team member">

                        <div class="team-social">                           
                            <a href="#"><i class="icofont-facebook"></i></a>
                            <a href="#"><i class="icofont-twitter"></i></a>                  
                            <a href="#"><i class="icofont-instagram"></i></a>                        
                        </div>
                    </div>
                    <div class="team-text">
                        <h4>Ana Kens</h4>
                        <p>Office Cleaner</p>
                    </div>
                </div>
            </div> 

            <div class="col-lg-3 col-md-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/7.png" alt="team member">

                        <div class="team-social">                           
                            <a href="#"><i class="icofont-facebook"></i></a>
                            <a href="#"><i class="icofont-twitter"></i></a>                  
                            <a href="#"><i class="icofont-instagram"></i></a>                        
                        </div>
                    </div>
                    <div class="team-text">
                        <h4>Zeck Thatc</h4>
                        <p>Car Cleaner</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/8.png" alt="team member">

                        <div class="team-social">                           
                            <a href="#"><i class="icofont-facebook"></i></a>
                            <a href="#"><i class="icofont-twitter"></i></a>                  
                            <a href="#"><i class="icofont-instagram"></i></a>                        
                        </div>
                    </div>
                    <div class="team-text">
                        <h4>Tinka Dew</h4>
                        <p>Assistant</p>
                    </div>
                </div>
            </div> 
        </div>
        <nav>
            <ul class="pagination  justify-content-center">
                <li class="page-item disabled">
                    <a class="page-link" href="#" tabindex="-1" aria-disabled="true">
                        <i class="icofont-rounded-left"></i>
                    </a>
                </li>
                <li class="page-item">
                    <a class="page-link" href="#">1</a>
                </li>
                <li class="page-item" aria-current="page">
                    <a class="page-link active" href="#">2</a>
                </li>
                <li class="page-item">
                    <a class="page-link" href="#">3</a>
                </li>
                <li class="page-item">
                    <a class="page-link" href="#">
                        <i class="icofont-rounded-right"></i>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</div>