<div class="service-title service-title-bg bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h2>Service Details</h2>
                    <ul>
                        <li>
                            <a routerLink="/index">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            <a routerLink="/service">Services</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Service Details
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-details-area main-service-area pt-100 services-details-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <img src="assets/img/service/4.png" alt="service">
                <div class="service-details-post">
                    <h3>House Cleaning <span> $29 Only</span></h3>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>

                    <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of the text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin.</p>

                    <ul>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Kitchen Cleaning
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Floor cleaning
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Balcony
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Window Cleaning
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Bathroom
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Door Cleaning
                        </li>
                    </ul>

                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita.</p>
                        
                    <p>Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus.</p>
     
                    <div class="theme-button">
                        <a routerLink="/pricing" class="default-btn">Book Now</a>
                    </div>
                </div>

                <div class="page-button">
                    <div class="row">
                        <div class="col-md-3 col-sm-6">
                            <div class="theme-button">
                                <a routerLink="/" class="default-btn">
                                    <i class="icofont-rounded-left"></i>
                                    Previous
                                </a>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-6 offset-md-6">
                            <div class="theme-button">
                                <a routerLink="/" class="default-btn">
                                    Next
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 main-service-area blog-side-bar">
                <div class="service-sidebar">
                    <form class="search-form">
                        <div class="form-group">              
                            <input type="text" class="form-control" placeholder="Search">
                            <button type="submit" class="btn btn-primary">
                                <i class="icofont-search-1"></i>
                            </button>
                        </div>
                    </form>
                    
                    <div class="service-list">
                        <p>Categories</p>                            
                        <ul>
                            <li>
                                <a routerLink="/">
                                    Toilet Cleaning 
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/">
                                    Stay Hygienic 
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/">
                                    News
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/">
                                    Cleaning Floor
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/">
                                    Cleaning Kitchen
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/">
                                    Wash Home
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="discount-text">
                        <h1>Get <span>15% Discount</span> on your first Service</h1>

                        <div class="theme-button">
                            <a routerLink="/" class="default-btn">Book Now</a>
                        </div>

                        <div class="discount-shapes">
                            <img src="assets/img/counter/1.png" alt="shape">
                            <img src="assets/img/counter/2.png" alt="shape">
                            <img src="assets/img/counter/3.png" alt="shape">
                            <img src="assets/img/counter/4.png" alt="shape">
                            <img src="assets/img/counter/5.png" alt="shape">
                            <img src="assets/img/counter/6.png" alt="shape">
                            <img src="assets/img/counter/7.png" alt="shape">
                            <img src="assets/img/counter/8.png" alt="shape">
                            <img src="assets/img/counter/5.png" alt="shape">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>