<div class="service-title service-title-bg bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h1>CURATENIE BIROURI </h1>
                    <ul>
                        <li>
                            <a routerLink="/">Acasa</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            <a routerLink="/firma-curatenie-brasov">Servicii de curatenie</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Curatenie Birouri
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-details-area main-service-area pt-100 services-details-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <img src="assets/img/service/curatanie birouri brasov.jpg" alt="curatanie birouri brasov">
                <div class="service-details-post">
                   
                    <p>Stim ca nu este deloc usor sa lucrezi intr-un mediu dezordonat si neingrijit. Mai stim si ca
                        <strong>productivitatea angajatilor tai</strong> depinde in mare masura de curatenia spatiului
                        in care isi desfasoara activitatea. Asa ca iti punem la dispozitie serviciile noastre de
                        curatenie in Brasov si zona limitrofa <strong>sub forma de abonament sau la cerere</strong>, la
                        preturi avantajoase pentru tine si afacerea ta. </p>


                    <h3>Curatenie birouri pentru angajati mai <span> fericiti</span></h3>
                    <p>Un mediu de lucru placut pentru angajatii tai inseamna un spatiu ingrijit si curat. Curatenia
                        este un factor foarte important pentru productivitatea angajatilor tai, de la igienizarea si
                        dezinfectarea grupurilor sanitare pana la mentinerea ordinii in bucatarii, sali de conferinta
                        si spatii de lucru sau zone de relaxare. </p>

                    <p>Chiar daca ai o vizita importanta din partea unor parteneri de afaceri sau pur si simplu doresti
                        sa le oferi angajatilor cel mai bun mediu de lucru, echipa noastra iti pune la dispozitie o gama
                        variata de servicii profesionale in domeniul curateniei. </p>
                    <h3>Servicii de curatenie birouri, sucursale bancare si sedii de firme
                    </h3>
                    <ul>
                        <li>
                            <i class="icofont-check-circled"></i>Intretinerea zilnica sau la cerere a birourilor</li>
                        <li>
                            <i class="icofont-check-circled"></i>Curatarea si dezinfectarea spatiilor comune</li>
                        <li>
                            <i class="icofont-check-circled"></i>Curatarea obiectelor de mobilier, stergerea birourilor
                            si a aparaturii</li>
                        <li>
                            <i class="icofont-check-circled"></i>Aspirarea mochetelor</li>
                        <li>
                            <i class="icofont-check-circled"></i>Curatarea mecanizata a pardoselei dure </li>
                        <li>
                            <i class="icofont-check-circled"></i>Curatarea canapelelor</li>
                        <li>
                            <i class="icofont-check-circled"></i>Dezinfectarea pardoselii, a usilor si a tocurilor de
                            usi, a punctelor de acces comun </li>
                        <li>
                            <i class="icofont-check-circled"></i>Dezinfectarea spatiilor prin nebulizare (ceata rece)
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>Spalarea geamurilor</li>
                        <li>
                            <i class="icofont-check-circled"></i>Curatarea fatadelor</li>
                        <li>
                            <i class="icofont-check-circled"></i>Colectarea gunoiului</li>
                        <li>
                            <i class="icofont-check-circled"></i>Igienizarea bucatariei si a bailor</li>


                    </ul>

                    <h3>De ce sa alegi o firma de curatenie profesionala?</h3>

                    <p>O echipa de specialisti va realiza toate aceste sarcini de curatenie intr-un mod eficient,
                        organizat si disciplinat astfel incat in fiecare dimineata, angajatii si clientii sa fie
                        intampinati de un aer proaspat si curat. </p>

                    <p>Siguranta tuturor este mai importanta ca oricand, de aceea punem accent pe procesul de
                        dezinfectare si igienzare.
                    </p>
                    <p>Venim pregatiti cu aparatura ultraperformanta si produse de curatare profesionale, astfel incat
                        sa eficientizam la maxim timpul efectiv de lucru.
                    </p>
                    <p>Oferim servicii de curatenie sub forma de solutii practice, personalizate. De aceea, te rugam sa
                        ne conactezi prin email sau telefonic pentru a beneficia de serviciile potrivite tie si afacerii
                        tale.
                    </p>
                  
                </div>

               
            </div>

            <div class="col-lg-4 main-service-area blog-side-bar">
                <app-sidebar></app-sidebar>
            </div>
        </div>
    </div>
</div>
