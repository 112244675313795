<div class="service-title service-title-bg bg-3 cleaning-special-service">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h1>SERVICII SPECIALE DE CURATARE BRASOV</h1>
                    <ul>
                        <li>
                            <a routerLink="/">Acasa</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Servicii de curatare
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Servicii speciale de curatare
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-details-area main-service-area pt-100 services-details-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <img src="assets/img/title-bg/servicii-speciale-de-curatenie-brasov.jpg"
                    alt="curatenie cabinete medicale saloane infrumusetare">
                <div class="service-details-post">
                  
                    <p>In echipa Allseam, suntem buni prieteni cu provocarile. De-a lungul timpului, am fost pusi in
                        fata unor situatii dificile in ceea ce priveste curatarea anumitor suprafete, insa le-am facut
                        fata cu brio.
                    </p>
                    <p>Ai nevoie de un serviciu special de curatenie? Ne deplasam oriunde in Brasov si in zonele
                        limitrofe.</p>


                    <h3>Curatare Copertine</h3>
                    <p>Fie ca vorbim despre exteriorul unui restaurant sau de terasa propriei locuinte, o copertine este
                        fara doar si poate, o solutie eficienta de umbrire si de protectie impotriva ploilor usoare.
                        Insa, pentru a te bucura cat mai multa vreme de beneficiile pe care copertina le ofera, este
                        recomandata curatarea periodica a acestora, cu solutii care sa nu afecteze materialul din care
                        sunt confectionate.</p>
                    <p>Copertinele sunt permanent expuse la factori externi intensi: aer, vant, apa, temperatura, dar si
                        la acumulari de praf, frunze sau zapada.</p>
                    <p>Apelati la servicii profesioniste în momentul în care va ganditi la reconditionarea copertinei,
                        pentru o curatare fara riscuri, cu echipamente adecvate de curatare si spuma activa.</p>
                    <h3>Curatare Graffiti</h3>

                    <p>Stim ca multa lume considera grafitti-ul o forma de arta stradala. Insa, atunci cand vine ca o
                        actiune de vandalism, pot aduce mari prejudicii afacerii tale - clientii ar putea crede ca nu
                        iti pasa de iamginea publica, iar asta dauneaza reputatiei afacerii tale.
                        In plus, un grafitti poate chiar devaloriza pretul unei proprietati private.
                        Venim la fata locului, pregatiti cu solutii eco-friendly, gata sa scoatem orice urma de grafitti
                        de pe fatadele cladirilor, geamuri, autobuze, poduri sau hale industriale.

                    </p>

                    <h3>Curatare Statui de Beton</h3>
                    <p>Statuile din beton pot oferi o personalitate aparte oricarui spatiu - de la gradini si terase,
                        pana la hoteluri, baruri si cafenele. Multe dintre ele reusesc sa-ti bucure privirea si sa
                        atraga atentia intr-un mod distinctiv. Dar ce faci cand timpul si intemperiile meteo isi spun
                        cuvantul?
                        Multe dintre ele pot fi curatate simplu, cu o solutie speciala, insa riscul ca acestea sa ramana
                        patate este foarte mare.
                    </p>
                    <p>Echipa noastra te ajuta sa reconditionezi orice tip de statuie, folosind aparate de curatare sub
                        presiune si solutii speciale, adecvate fiecarui tip de material.</p>
                    <br>
                    <p>Ai o provocare pentru noi? Grozav! Abia asteptam sa ne povestesti!</p>

                </div>


            </div>

            <div class="col-lg-4 main-service-area blog-side-bar">
                <app-sidebar></app-sidebar>
            </div>
        </div>
    </div>
</div>
