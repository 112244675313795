<div class="team-title team-title-bg">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="team-title-text">
                    <h2>Serviciile noastre</h2>
                    <ul>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Serviciile noastre
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="team-section team-style-four pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <a routerLink="/curatare-canapele-fotolii-scaune-brasov" class="team-card">
                    <div class="team-img">
                        <img src="assets/img/cleaning/curatare canapele brasov.jpg" alt="curatare canapele brasov">
                    </div>
                    <div class="team-text">
                        <h4>CURATARE CANAPELE, FOTOLII, SALTELE</h4>
                    </div>
                </a>
            </div> 
            
            <div class="col-lg-4 col-md-6">
                <a routerLink="/firma-curatare-podele-pavaje-brasov" class="team-card">
                    <div class="team-img">
                        <img src="assets/img/service/curatenie la domiciliu brasov.jpg" alt="curatare mecanizata podele si pavaje brasov">
                    </div>
                    <div class="team-text">
                        <h4>CURATARE MECANIZATA PODELE SI PAVAJE</h4>
                    </div>
                </a>
            </div> 
            <div class="col-lg-4 col-md-6">
                <a routerLink="/firma-curatare-echipamente-horeca-brasov" class="team-card">
                    <div class="team-img">
                        <img src="assets/img/service/curatenie scari de-bloc brasov.jpg" alt="curatare echipamente horeca brasov">
                    </div>
                    <div class="team-text">
                        <h4>CURATARE ECHIPAMENTE HORECA</h4>
                    </div>
                </a>
            </div> 
            <div class="col-lg-4 col-md-6">
                <a routerLink="/curatare-tapiterie-auto-detailing-auto-brasov" class="team-card">
                    <div class="team-img">
                        <img src="assets/img/service/curatenie horeca brasov.jpg" alt="curatare tapiterie auto detailing auto brasov">
                    </div>
                    <div class="team-text">
                        <h4>CURATARE TAPITERIE AUTO SI DETAILING AUTO</h4>
                    </div>
                </a>
            </div> 
            <div class="col-lg-4 col-md-6">
                <a routerLink="/servicii-speciale-de-curatare-brasov" class="team-card">
                    <div class="team-img">
                        <img src="assets/img/service/curatenie dupa constructor brasov.jpg" alt="servicii speciale de curatare brasov">
                    </div>
                    <div class="team-text">
                        <h4>SERVICII SPECIALE DE CURATARE</h4>
                    </div>
                </a>
            </div> 
            
        </div>
    </div>
</div>