<div class="service-title service-title-bg">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h2>Our Recent Projects</h2>
                    <ul>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Recent Projects
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="recent-style-two pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="recent-items">
                    <div class="recent-img">
                        <img src="assets/img/recent-work/1.png" alt="project image">

                        <div class="recent-hover">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <a href="assets/img/recent-work/1.png" class="popup-gallery">
                                        <i class="icofont-eye-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="recent-items">
                    <div class="recent-img">
                        <img src="assets/img/recent-work/2.png" alt="project image">

                        <div class="recent-hover">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <a href="assets/img/recent-work/2.png" class="popup-gallery">
                                        <i class="icofont-eye-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="recent-items">
                    <div class="recent-img">
                        <img src="assets/img/recent-work/3.png" alt="project image">

                        <div class="recent-hover">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <a href="assets/img/recent-work/3.png" class="popup-gallery">
                                        <i class="icofont-eye-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="recent-items">
                    <div class="recent-img">
                        <img src="assets/img/recent-work/4.png" alt="project image">

                        <div class="recent-hover">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <a href="assets/img/recent-work/4.png" class="popup-gallery">
                                        <i class="icofont-eye-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="recent-items">
                    <div class="recent-img">
                        <img src="assets/img/recent-work/5.png" alt="project image">

                        <div class="recent-hover">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <a href="assets/img/recent-work/5.png" class="popup-gallery">
                                        <i class="icofont-eye-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="recent-items">
                    <div class="recent-img">
                        <img src="assets/img/recent-work/11.png" alt="project image">

                        <div class="recent-hover">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <a href="assets/img/recent-work/11.png" class="popup-gallery">
                                        <i class="icofont-eye-alt"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="process-section process-style-two process-style-four pb-70">
    <div class="container">
        <div class="section-head blue-title text-center">
            <h2>How Do We <span>Work</span></h2>
            <p>Lorem Ipsum is simply dummied text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</p>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="process-card">
                    <i class="icofont-touch"></i>
                    <h3>Choose Services</h3>
                    <p>Lorem Ipsum is simply dummied text of the printing and typesetting industry.</p>
                    <img src="assets/img/process-pattern-2.png" alt="arrow">
                </div>
            </div> 
            
            <div class="col-md-4">
                <div class="process-card">
                    <i class="icofont-world"></i>
                    <h3>Booking Online</h3>
                    <p>Lorem Ipsum is simply dummied text of the printing and typesetting industry.</p>
                    <img src="assets/img/process-pattern-2.png" alt="arrow">
                </div>
            </div> 

            <div class="col-md-4">
                <div class="process-card">
                    <i class="icofont-recycle-alt"></i>
                    <h3>Enjoy Cleaning</h3>
                    <p>Lorem Ipsum is simply dummied text of the printing and typesetting industry.</p>
                </div>
            </div> 
        </div>
    </div>
</div>