<div class="home-section">
    <div class="home-slider-area owl-carousel owl-theme">
        <div class="home-slider-item items-bg1">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="home-text">
                            <h1>Nimic Nu Se Compara Cu </h1>
                            <h2><span>Starea de Bine</span> Oferita De Curatenie</h2>
                            <p>Dureaza doar 60 de secunde sa rezervi. Apoi te bucuri de timpul tau liber.</p>
                            <div class="theme-button">

                                <a href="tel:+40758116325" class="default-btn active">Suna</a>
                                <a routerLink="/contact" class="default-btn">Cere oferta</a>
                            </div>
                        </div>
                        <div class="home-img">
                            <img src="/assets/img/home/slider-1-content.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="home-slider-item items-bg2">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="home-text">
                            <h1>We provide High Quality Service</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Viverra maecenas accumsan lacus vel facilisis. Quis ipsum suspendisse ultrices gravida.</p>
                            <div class="theme-button">
                                <a routerLink="/about" class="default-btn active-btn">Know More</a>
                                <a routerLink="/contact" class="default-btn">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="home-slider-item items-bg3">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="home-text">
                            <h1>Our Working Process is Unique</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Viverra maecenas accumsan lacus vel facilisis. Quis ipsum suspendisse ultrices gravida.</p>
                            <div class="theme-button">
                                <a routerLink="/about" class="default-btn active-btn">Know More</a>
                                <a routerLink="/contact" class="default-btn">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>

<section class="service-section pt-100 pb-70">
    <div class="container">
        <div class="section-head text-center">
            <h2>Servicii de <span>Curatenie Profesionala</span> in Brasov </h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="icon-happy"></i>
                    <h3>Echipa de Incredere</h3>
                    <p>Se zvoneste ca avem cei mai buni oameni, dar nu ne place sa ne laudam. Asa ca te lasam sa te
                        convingi.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card active-service">
                    <i class="icon-fingerprint"></i>
                    <h3>Solutii Personalizate</h3>
                    <p>Suntem diferiti si avem nevoi diferite, asa ca este normal sa-ti oferim solutii de curatenie care
                        sa-ti serveasca necesitatile.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="icon-positive-review"></i>
                    <h3>100% Satisfactie</h3>
                    <p>Nu plecam pana cand nu suntem pe deplin multumiti de rezultat. Si trebuie sa stii ca avem
                        standarde foarte ridicate.</p>
                </div>
            </div>


        </div>

        <div class="service-shapes">
            <img src="assets/img/service/shapes/1.png" alt="shape">
            <img src="assets/img/service/shapes/2.png" alt="shape">
            <img src="assets/img/service/shapes/3.png" alt="shape">
            <img src="assets/img/service/shapes/4.png" alt="shape">
            <img src="assets/img/service/shapes/5.png" alt="shape">
            <img src="assets/img/service/shapes/6.png" alt="shape">
            <img src="assets/img/service/shapes/7.png" alt="shape">
            <img src="assets/img/service/shapes/8.png" alt="shape">
            <img src="assets/img/service/shapes/9.png" alt="shape">
            <img src="assets/img/service/shapes/10.png" alt="shape">
        </div>
    </div>
</section>

<app-biocid-section></app-biocid-section>
<section class="hire-section">
    <div class="hire-section__container">
        <div class="hire-section__content">
            <div class="hire-text ptb-100">
                <div class="section-head blue-title">
                    <h2>Servicii de Curatenie la Domiciliu</h2>
                    <p>Ce bine e acasa cand totul e curat si dezinfectat!</p>
                      <p>Acasa ar trebui sa fie locul in care ne simtim cel mai bine si in siguranta.
                        Facem din casa ta locul preferat de joaca:
                    </p>
                </div>
                <div class="row">
                    <div class="col-lg-5 col-md-5 col-sm-6 col-xs-12 p-0 mr-20">
                        <ul>
                            <li><i class="icofont-check-circled"></i>
                                Curatenie Generala</li>
                            <li><i class="icofont-check-circled"></i>
                                Curatenie de Intretinere</li>
                            <li><i class="icofont-check-circled"></i>
                                Curatenie Sezoniera</li>
                            <li><i class="icofont-check-circled"></i>
                                Igienizare & Dezinfectare</li>

                        </ul>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 p-0">
                        <ul>
                            <li><i class="icofont-check-circled"></i>Curatenie dupa Renovare</li>
                            <li><i class="icofont-check-circled"></i>Abonamente Personalizate</li>
                            <li><i class="icofont-check-circled"></i>Abonamente Standard</li>
                            <li><i class="icofont-check-circled"></i>Curatare Canapele & Saltele</li>
                        </ul>
                    </div>
                </div>
                <div class="theme-button"><a routerlink="/curatenie-la-domiciliu-brasov" class="default-btn" ng-reflect-router-link="/curatenie-la-domiciliu-brasov"
                    href="/curatenie-la-domiciliu-brasov">Vezi mai multe</a></div>
            </div>
        </div>
        <div class="hire-section__img">
            <div class="hire-img hire-img--1"></div>
        </div>
    </div>
    <div class="hire-section__container">
        <div class="hire-section__img">
            <div class="hire-img hire-img--2"></div>
        </div>
        <div class="hire-section__content">
            <div class="hire-text ptb-100">
                <div class="section-head blue-title">
                    <h2>Servicii de Curatenie pentru Business</h2>
                    <p>Afacerea noastra este sa facem ca afacerea ta sa straluceasca.</p>
                        <p>Oferim solutii personalizate pentru zona de activitate business, indiferent de domeniu:
                    </p>
                </div>
                <div class="row">
                    <div class="col-lg-5 col-md-5 col-sm-6 p-0 mr-20">
                        <ul>
                            <li><i class="icofont-check-circled"></i>Curatenie Birouri </li>
                            <li><i class="icofont-check-circled"></i>Sucursale Bancare</li>
                            <li><i class="icofont-check-circled"></i>Saloane de Infrumusetare</li>
                            <li><i class="icofont-check-circled"></i>Cabinete Medicale</li>
                            <li><i class="icofont-check-circled"></i>Zona HoReCa</li>


                        </ul>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 p-0">
                        <ul>

                            <li><i class="icofont-check-circled"></i>Curatenie dupa Constructor</li>
                            <li><i class="icofont-check-circled"></i>Curatenie Scari de Bloc</li>
                            <li><i class="icofont-check-circled"></i>Igienizare & Dezinfectare</li>
                            <li><i class="icofont-check-circled"></i>Curatare Canapele & Mochete</li>
                            <li><i class="icofont-check-circled"></i>Curatare Pavaje & Copertine</li>


                        </ul>
                    </div>
                </div>
                <div class="theme-button"><a routerlink="/contact" class="default-btn" ng-reflect-router-link="/contact"
                        href="/contact">Cere Oferta Personalizata</a></div>
            </div>
        </div>

    </div>

</section>

<app-testimonial></app-testimonial>

<div class="service-style-three pt-100 pb-70">
    <div class="container">
        <div class="section-head text-center">
          
            <h2>Servicii de Curatenie</h2>
           
        </div>

        <div class="service-slider owl-carousel owl-theme">
            <div class="service-item">
                <i class="flaticon-cleaning-spray"></i>
                <h3>Curatenie Birouri
                </h3>
               

                <div class="theme-button">
                    <a routerLink="/igienizare-dezinfectie-spatii-brasov" class="default-btn">Vezi detalii</a>
                </div>

                <div class="service-shape">
                    <img src="assets/img/service/shapes/service-pattern-6.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-7.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-8.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-9.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-10.png" alt="shape">
                </div>
            </div>

            <div class="service-item">
                <i class="flaticon-clean-house"></i>
                <h3>Curatenie la Domiciliu</h3>
               

               <div class="theme-button">
                    <a routerLink="/service-details" class="default-btn">Vezi detalii</a>
                </div>

                <div class="service-shape">
                    <img src="assets/img/service/shapes/service-pattern-6.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-7.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-8.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-9.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-10.png" alt="shape">
                </div>
            </div>

            <div class="service-item">
                <i class="flaticon-wiper"></i>
                <h3>Curatenie Scari de Bloc</h3>
                

               <div class="theme-button">
                    <a routerLink="/service-details" class="default-btn">Vezi detalii</a>
                </div>

                <div class="service-shape">
                    <img src="assets/img/service/shapes/service-pattern-6.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-7.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-8.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-9.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-10.png" alt="shape">
                </div>
            </div>

           

            <div class="service-item">
                <i class="flaticon-clean-house"></i>
                <h3>Curatenie HoReCa</h3>
                

               <div class="theme-button">
                    <a routerLink="/service-details" class="default-btn">Vezi detalii</a>
                </div>

                <div class="service-shape">
                    <img src="assets/img/service/shapes/service-pattern-6.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-7.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-8.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-9.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-10.png" alt="shape">
                </div>
            </div>

            <div class="service-item">
                <i class="flaticon-wiper"></i>
                <h3>Curatenie dupa Constructor</h3>
                

               <div class="theme-button">
                    <a routerLink="/service-details" class="default-btn">Vezi detalii</a>
                </div>

                <div class="service-shape">
                    <img src="assets/img/service/shapes/service-pattern-6.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-7.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-8.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-9.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-10.png" alt="shape">
                </div>
            </div>
            <div class="service-item">
                <i class="flaticon-wiper"></i>
                <h3>Curatenie Saloane & Cabinete
                </h3>
                

               <div class="theme-button">
                    <a routerLink="/service-details" class="default-btn">Vezi detalii</a>
                </div>

                <div class="service-shape">
                    <img src="assets/img/service/shapes/service-pattern-6.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-7.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-8.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-9.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-10.png" alt="shape">
                </div>
            </div>
        </div>
    </div>
</div>

<app-portfolio-slider></app-portfolio-slider>

<app-logo-section></app-logo-section>