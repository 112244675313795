<div class="header-section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-9 col-md-12">
                <div class="header-widget">
                    <ul>
                        <li><i class="icofont-clock-time"></i> 
                            Info și programări L-V: 10-18 | 
                            Program de lucru L-V: non-stop</li>

                        <li><i class="icofont-location-pin"></i> Brasov City</li>
                        <li><i class="icofont-phone"></i> <a href="tel:+40758116325">0758-116-325</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-md-3">
                <div class="header-social text-right">
                    <ul>
                        <li><a target="_blank" href="https://www.facebook.com/AllSteam01/"><i
                                    class="icofont-facebook"></i></a></li>
                        <li><a target="_blank" href="https://www.instagram.com/allsteam9/"><i
                                    class="icofont-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo">
            <img src="assets/img/logo2.png" alt="logo">
        </a>
    </div>

    <div class="main-nav">
        <div class="container-menu">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" href="index.html">
                    <img src="assets/img/logo2.png" alt="logo">
                </a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a routerLink="/" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Acasa</a>
                        </li>



                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Servicii curatenie <i
                                    class="icofont-rounded-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/curatenie-birouri-brasov" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Curatenie Birouri</a></li>
                                <li class="nav-item"><a routerLink="/curatenie-la-domiciliu-brasov" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Curatenie la Domiciliu</a></li>
                                <li class="nav-item"><a routerLink="/curatenie-scari-de-bloc-brasov" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Curatenie Scari de Bloc</a></li>
                                <li class="nav-item"><a routerLink="/curatenie-restaurante-cafenele-baruri-brasov" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Curatenie HoReCa</a></li>
                                <li class="nav-item"><a routerLink="/curatenie-dupa-constructor-renovare-brasov" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Curatenie dupa Constructor</a></li>
                                <li class="nav-item"><a routerLink="/curatenie-saloane-infrumusetare-cabinete-medicale-brasov" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Curatenie Saloane & Cabinete</a></li>


                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Curatare <i
                                    class="icofont-rounded-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/curatare-canapele-fotolii-scaune-brasov" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Canapele, Fotolii & Saltele</a></li>
                                <li class="nav-item"><a routerLink="/firma-curatare-podele-pavaje-brasov" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Pardoseala & Pavaje</a></li>
                                <li class="nav-item"><a routerLink="/firma-curatare-echipamente-horeca-brasov" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Echipamente HoReCa</a></li>
                                <li class="nav-item"><a routerLink="/curatare-tapiterie-auto-detailing-auto-brasov" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Tapiterie Auto & Detailing</a></li>
                                <li class="nav-item"><a routerLink="/servicii-speciale-de-curatare-brasov" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Servicii Speciale</a></li>


                            </ul>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/igienizare-dezinfectie-spatii-brasov" class="nav-link"  routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Igenizare si Dezinfectie</a>

                           
                        </li>

                        <li class="nav-item">
                            <a routerLink="/preturi-servicii-curatenie-brasov" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Preturi</a>
                        </li>
                        <li class="nav-item"><a routerLink="/despre-noi" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Despre noi</a></li>
                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                    </ul>

                    <div class="call-us ml-auto">
                        <img src="/assets/img/mobile-icon.svg" alt="mobile-icon">
                        <p>
                            Suna pentru curatenie!
                            <span><a href="tel:+40758116325">0758-116-325</a></span>
                        </p>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
