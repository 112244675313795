import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confidentiality',
  templateUrl: './confidentiality.component.html',
  styleUrls: ['./confidentiality.component.scss']
})
export class ConfidentialityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
