<div class="service-title service-title-bg bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h1>CURATENIE SALOANE BEAUTY SI CABINETE MEDICALE</h1>
                    <ul>
                        <li>
                            <a routerLink="/">Acasa</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            <a routerLink="/firma-curatenie-brasov">Servicii de curatenie</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Curatenie saloane beauty si cabinete medicale
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-details-area main-service-area pt-100 services-details-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <img src="assets/img/service/curatenie cabinete medicale saloane infrumusetare.jpg"
                    alt="curatenie cabinete medicale saloane infrumusetare">
                <div class="service-details-post">
                    <h1>CURATENIE SALOANE BEAUTY SI CABINETE MEDICALE</h1>
                    <p>Curatenia si igiena sunt fara doar si poate aspecte esentiale atunci cand vorbim despre un
                        cabinet medical sau un salon de infrumusetare. Siguranta este un cuvant cheie aici, asa ca este
                        necesar sa o oferi clientilor, asigurand un mediu curat si dezinfectat.
                    </p>


                    <h3>Curatenie saloane de infrumusetare -
                        <span>igiena fara compromisuri</span></h3>
                    <p>Atunci cand clientii trec pragul unui salon de infrumusetare, se asteapta sa primeasca nu doar
                        serviciul propriu-zis, ci mai ales o experienta placuta si revigoranta. Acest lucru este
                        influentat in mare masura de imaginea de ansamblu a salonului, care depinde la randul ei de
                        gradul de curatenie cu care acestia sunt intampinati.</p>
                    <h3>Pentru clienti care se intorc intotdeauna cu placere, va oferim:</h3>

                    <ul>
                        <li>
                            <i class="icofont-check-circled"></i>Curatenie generala in cadrul salonului de cosmetica si
                            infrumusetare</li>
                        <li>
                            <i class="icofont-check-circled"></i>Dezinfectarea tuturor spatiilor frecvent atinse</li>
                        <li>
                            <i class="icofont-check-circled"></i>Igienizarea instrumentelor folosite in activitatea de
                            zi cu zi</li>
                        <li>
                            <i class="icofont-check-circled"></i>Curatarea si igienizarea bailor si a vaselor de
                            toateta</li>
                        <li>
                            <i class="icofont-check-circled"></i>Spalarea geamurilor accesibile si a oglinzilor</li>
                        <li>
                            <i class="icofont-check-circled"></i>Curatarea mecanizata a podelelor dure</li>
                        <li>
                            <i class="icofont-check-circled"></i>Curatarea copertinelor retractabile si a reclamelor
                            luminoase</li>
                        <li>
                            <i class="icofont-check-circled"></i>Curatarea canapelelor textile sau de piele din sala de
                            asteptare</li>
                    </ul>
                    <h3>Curatenie saloane medicale - siguranta inainte de toate</h3>
                    <ul>
                        <li>
                            <i class="icofont-check-circled"></i>Sterilizarea si igienizarea tuturor obiectelor si a
                            spatiilor frecvent atinse de catre clienti</li>
                        <li>
                            <i class="icofont-check-circled"></i>Dezinfectarea podelelor cu solutii sigure si non
                            invazive pentru sanatatea umana</li>
                        <li>
                            <i class="icofont-check-circled"></i>Dezinfectarea prin nebulizare (ceata rece) a tuturor
                            spatiilor greu accesibile pentru curatarea obisnuita</li>
                        <li>
                            <i class="icofont-check-circled"></i>Dezinfectarea si igienizarea bailor si a vaselor de
                            toaleta</li>
                        <li>
                            <i class="icofont-check-circled"></i>Curatarea mecanizata a podelelor dure</li>
                        <li>
                            <i class="icofont-check-circled"></i>Curatarea copertinelor retractabile si a reclamelor
                            luminoase</li>
                        <li>
                            <i class="icofont-check-circled"></i>Spalarea geamurilor accesibile si a oglinzilor</li>
                        <li>
                            <i class="icofont-check-circled"></i>Curatarea canapelelor textile sau de piele din sala de
                            asteptare</li>
                    </ul>
                    <h3>Allsteam - partenerul tau de curatenie din Brasov</h3>
                    <p>
                        Indiferent este vorba despre un salon de cosmetica & infrumusetare, sau un cabinet medical ori
                        de stomatologie, echipa noastra se deplaseaza cu drag, oriunde in Brasov si in zonele limitrofe,
                        pentru a efectua o curatenie ireprosabila.
                    </p>

                  
                </div>

                
            </div>

            <div class="col-lg-4 main-service-area blog-side-bar">
                <app-sidebar></app-sidebar>
            </div>
        </div>
    </div>
</div>
