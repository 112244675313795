import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cleaning-rezidential-stairs',
  templateUrl: './cleaning-rezidential-stairs.component.html',
  styleUrls: ['./cleaning-rezidential-stairs.component.scss']
})
export class CleaningRezidentialStairsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
