<div class="service-style-two pt-100">
    <div class="container">
       

        <div class="service-slider-wrapper owl-carousel owl-theme">
            <div class="row align-items-center bg-white">
                <div class="col-lg-4">
                    <div class="service-img">
                        <img src="assets/img/parteneri/logo-fun-house-after-school.png" >
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="service-img">
                        <img src="assets/img/parteneri/logo-gourmet.jpg" >
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="service-img">
                        <img src="assets/img/parteneri/luado-chocolate.jpg" >
                    </div>
                </div>
               
            </div>                
        </div>
    </div>
</div>