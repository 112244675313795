<div class="counter-section pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="offer-text">
                    <h2>Te ajutam sa elimini cei mai periculosi inamici. <span>Igienizare prin nebulizare </span></h2>

                    <div class="theme-button">
                        <a routerLink="/igienizare-dezinfectie-spatii-brasov" class="default-btn">Vezi detalii</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="counter-area">

                </div>
            </div>
        </div>

        <div class="counter-shape">
            <img src="assets/img/counter/1.png" alt="shape">
            <img src="assets/img/counter/2.png" alt="shape">
            <img src="assets/img/counter/3.png" alt="shape">
            <img src="assets/img/counter/4.png" alt="shape">
            <img src="assets/img/counter/5.png" alt="shape">
            <img src="assets/img/counter/6.png" alt="shape">
            <img src="assets/img/counter/7.png" alt="shape">
            <img src="assets/img/counter/1.png" alt="shape">
            <img src="assets/img/counter/8.png" alt="shape">
            <img src="assets/img/counter/4.png" alt="shape">
            <img src="assets/img/counter/shape-1.png" alt="bubble shape">
            <img src="assets/img/counter/shape-1.png" alt="bubble shape">
        </div>
    </div>
</div>