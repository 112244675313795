<div class="about-title about-title-bg service-title service-title-bg bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="about-title-text">
                    <h2>Despre noi</h2>
                    <ul>
                        <li><a routerLink="/">Acasa</a></li>
                        <li><i class="icofont-rounded-double-right"></i> Despre noi</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-style-two about-style-three pt-100 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 p-0">
                <div class="about-img">
                    <img src="assets/img/allsteam-despre-noi.jpg"
                        alt="about image">
                    </div>
            </div>
            <div class="col-lg-6">
                <div class="about-text">
                    <div class="section-head">
                        <h2>Suntem 100% dedicati necesitatilor tale
                        </h2>
                        <p>Suntem 100% dedicati necesitatilor tale
                        </p>
                    </div>
                    <ul>
<li><i class="icofont-check-circled"></i> Suntem flexibili</li>
<li><i class="icofont-check-circled"></i> Curatam repede & bine</li>
<li><i class="icofont-check-circled"></i> Iubim provocarile</li>
<li><i class="icofont-check-circled"></i> Aparatura ultraperformanta</li>
<li><i class="icofont-check-circled"></i> Solutii profesionale</li>
<li><i class="icofont-check-circled"></i> Echipa cu experienta</li>

                       
                    </ul>
                    <p>Ne deplasam la tine, in mod gratuit, oriunde in Brasov. Pentru zonele limitrofe, tariful este de 2 RON / km dus-intors</p>
                </div>
            </div>
        </div>
    </div>
</section>


<app-portfolio-slider></app-portfolio-slider>


<div class="process-section process-style-two pt-100 pb-70">
    <div class="container">
        <div class="section-head blue-title text-center">
            <h2>Cum <span>Lucram</span></h2>
            <p>E simplu sa ne contactezi! Dureaza doar 60 de secunde, apoi te bucuri de curatenie si de timpul tau
                liber.</p>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="process-card">
                    <i class="icon-phone"></i>
                    <h3>Ne contactezi</h3>
                    <p>Ne poti suna, scrie pe whatsapp sau trimite un email
                    </p>
                    <img src="assets/img/process-pattern-2.png" alt="arrow">
                </div>
            </div>

            <div class="col-md-4">
                <div class="process-card">
                    <i class="icon-cleaning"></i>
                    <h3>Ne deplasam la locatia ta</h3>
                    <p>Stabilim de comun acord ziua si ora cand venim la tine</p>
                    <img src="assets/img/process-pattern-2.png" alt="arrow">
                </div>
            </div>

            <div class="col-md-4">
                <div class="process-card">
                    <i class="icon-relaxing"></i>
                    <h3>Te relaxezi</h3>
                    <p>Noi facem curatenie, tu te bucuri de timpul castigat</p>
                </div>
            </div>
        </div>
    </div>
</div>


<app-testimonial></app-testimonial>