<div class="service-title service-title-bg bg-3 cleaning-horeca">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h1>CURATARE ECHIPAMENTE HORECA</h1>
                    <ul>
                        <li>
                            <a routerLink="/">Acasa</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Servicii de curatare
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Curatare echipamente HORECA
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-details-area main-service-area pt-100 services-details-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <img src="assets/img/title-bg/curatare-echipament-horeca-brasov.jpg"
                    alt="curatenie cabinete medicale saloane infrumusetare">
                <div class="service-details-post">
                   
                    <p>Curatarea, igienizarea si dezinfectarea bucatariei pentru industria serviciilor alimentare din
                        zona HoReCa sunt operatiuni care nu au cum sa fie excluse. O bucatarie murdara, neglijata, cu
                        echipamente necuratate corespunzator si in profunzime devine un mediu propice pentru raspandirea
                        virusurilor si a bacteriilor.
                    </p>
                    <p>In plus, curatate frecvent, echipamentele profesionale folosite in restauratenele hotelurilor, a
                        cafenelelor si a barurilor isi vor prelungi considerabil durata de viata si-ti vor sustine
                        afacerea pentru mai mult timp.</p>

                    <h3>De ce este necesar sa mentii curate echipamentele din bucatarii?</h3>
                    <p>Acesta este, de fapt, primul si cel mai important aspect de luat în considerare atunci cand
                        administrati un spatiu comercial de bucatarie. Orice altceva - decor, ambianta, mancare,
                        varietate, pret - care determina buna desfasurare al afacerii tale, vin imediat dupa ca si
                        importanta in ceea ce priveste siguranta si multumirea clientilor tai. </p>
                    <h3>Ce curatam in bucatariile din zona HoReCa?
                    </h3>

                    <ul>
                        <li>
                            <i class="icofont-check-circled"></i>Sisteme de exhaustare</li>
                        <li>
                            <i class="icofont-check-circled"></i>Hote & motoare de hota</li>
                        <li>
                            <i class="icofont-check-circled"></i>Aragaze profesionale</li>
                        <li>
                            <i class="icofont-check-circled"></i>Cuptoare catalitice</li>
                        <li>
                            <i class="icofont-check-circled"></i>Echipamente de lucru</li>
                        <li>
                            <i class="icofont-check-circled"></i>Peretii acoperiti de faianta</li>
                        <li>
                            <i class="icofont-check-circled"></i>Gresia si podeaua cu suprafata dura</li>
                        <li>
                            <i class="icofont-check-circled"></i>Chiuvete</li>
                        <li>
                            <i class="icofont-check-circled"></i>Cuptoare cu microunde </li>
                        <li>
                            <i class="icofont-check-circled"></i>Masini de spalat vase</li>
                        <li>
                            <i class="icofont-check-circled"></i>Mese & blaturi de lucru</li>


                    </ul>
                    <h3>Curatarea nu este suficienta intr-o bucatarie HoReCa</h3>
                    <p>De aceea, imbinam curatarea profesionala cu serviciile de <strong>curatenie, igienizare si
                            dezinfectie</strong> la
                        standarde inalte, folosind aparatura ultraperformanta, tehnici si produse profesionale
                        eficiente, dar care nu contin substante chimice daunatoare organismului uman.</p>
                    <p>Venim pregatiti cu:</p>
                    <ul>
                        <li>
                            <i class="icofont-check-circled"></i>Steamer de curatat cu aburi (8 bari presiune) </li>
                        <li>
                            <i class="icofont-check-circled"></i>Aspirator profesional cu 2 motoare </li>
                        <li>
                            <i class="icofont-check-circled"></i>Detergenti si solutii de curatat avizate BIOCID</li>
                        <li>
                            <i class="icofont-check-circled"></i>O echipa pusa pe treaba, cu experienta in acest tip de
                            curatare</li>

                    </ul>
                    <p>Pentru bucatarii HoReCa ingrijite si aparatura perfect igienizata, ne deplasam oriunde in Brasov si in zonele limitrofe. </p>

                </div>

            
            </div>

            <div class="col-lg-4 main-service-area blog-side-bar">
                <app-sidebar></app-sidebar>
            </div>
        </div>
    </div>
</div>
