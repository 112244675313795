<div class="service-title service-title-bg bg-3 cleaning-auto">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h1>CURATARE TAPITERIE AUTO SI DETAILING AUTO</h1>
                    <ul>
                        <li>
                            <a routerLink="/">Acasa</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Servicii de curatare
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Curatare tapiterie auto si detailing auto
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-details-area main-service-area pt-100 services-details-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <img src="assets/img/title-bg/curatare-tapiterie-auto-detailing-auto-brasov.jpg"
                    alt="curatenie cabinete medicale saloane infrumusetare">
                <div class="service-details-post">
                    
                    <p>O masina curata presupune mai mult decat aspectul ingrijit care se poate observa la o prima
                        vedere. Este cu mult mai important ca germenii si bacteriile sa fie pastrate la distanta, pentru
                        a mentine un mediu sigur si sanatos, de fiecare data cand conduci.
                    </p>


                    <h3>Curatare tapiterie auto - pentru ca viata se intampla in timp ce conduci</h3>
                    <p>In masina, “life happens” si asta e normal. Autoturismul este mai mult decat un mijloc de
                        transport destinatie si retur. E despre drumul copiilor la scoala, in fiecare zi. E despre
                        calatoriile la mare si toate planurile de vacanta. Pentru unii, reprezinta cea mai tare sesiune
                        de karaoke. Pentru altii e sala de conferinte online. Altii savureaza pranzul aici. Pentru multi
                        este locul de munca full-time. </p>
                    <p>Rolul nostru in aceasta ecuatie este foarte simplu - va ajutam sa pastrati masina curata si
                        igienizata, departe de murdarie si bacterii, facand loc pentru noi amintiri, in fiecare zi.</p>
                    <h3>Ce inseamna detailing auto?
                    </h3>

                    <p>Operatiunea de detailing auto depaseste cu mult cosmetica auto. Desi in aparenta, masina poate
                        parea curata, inamicii invizibili se pot inmulti necontrolat. Resturile alimentare, parul de
                        animale, praful, acarienii si mai ales vaporii toxici din trafic pot cauza probleme de sanatate
                        pentru sofer si pasager. Aici intervine procedeul de detailing auto.
                        Folosind solutii specifice fiecarui tip de suprafata - plastic, textil, piele, cauciuc - echipa
                        noastra va curata, degresa si igieniza in profunzime masina pana in cele mai mici detalii:
                    </p>

                    <ul>

                        <li>
                            <i class="icofont-check-circled"></i>Curatare si uscare tapiterie textila cu injectie
                            extractie</li>

                        <li>
                            <i class="icofont-check-circled"></i>Curatare si ingrijire tapiterie de piele</li>

                        <li>
                            <i class="icofont-check-circled"></i>Curatare mocheta</li>

                        <li>
                            <i class="icofont-check-circled"></i>Aspirare si curatare amanuntita</li>

                        <li>
                            <i class="icofont-check-circled"></i>Curatare plafon</li>

                        <li>
                            <i class="icofont-check-circled"></i>Detailing tablou de bord si consola </li>

                        <li>
                            <i class="icofont-check-circled"></i>Detailing panouri de usi</li>

                        <li>
                            <i class="icofont-check-circled"></i>Curatarea portbagajului</li>

                        <li>
                            <i class="icofont-check-circled"></i>Curatarea si dezinfectarea centurilor de siguranta</li>

                        <li>
                            <i class="icofont-check-circled"></i>Curatarea si igienizarea scaunelor de copii</li>




                    </ul>
                    <p>Venim noi la tine, oriunde in Brasov sau in zonele limitrofe. Folosim produse de calitate si echipamente performante, pentru a ne asigura ca petele de murdarie nu au nicio sansa. De asemenea, mirosul de tutun va fi doar o amintire - tot ce vom lasa in urma va fi o masina care va arata si se va simti proaspata, aproape ca  noua.</p>

                </div>


            </div>

            <div class="col-lg-4 main-service-area blog-side-bar">
                <app-sidebar></app-sidebar>
            </div>
        </div>
    </div>
</div>
