<div class="service-title service-title-bg bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h2>CURATENIE LA DOMICILIU </h2>
                    <ul>
                        <li>
                            <a routerLink="/">Acasa</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            <a routerLink="/firma-curatenie-brasov">Servicii de curatenie</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Curatenie la domiciliu
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-details-area main-service-area pt-100 services-details-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <img src="assets/img/service/curatenie la domiciliu brasov.jpg" alt="curatenie la domiciliu brasov">
                <div class="service-details-post">
                  
                    <p>Acasa este si locul nostru preferat, mai ales atunci cand totul este curat, dezinfectat si
                        ordonat. Sa faci curatenie in profunzime, nu este o treaba deloc usoara si nici rapida. Partea
                        buna este ca noua ne place sa facem asta, iar tu te poti bucura de atat de mult timp liber
                        facand lucruri care iti aduc cu adevarat zambetul pe buze.
                        Echipa noastra face curatenie cu atentie la cele mai mici detalii in garsoniere, apartamente cu
                        2, 3 sau mai multe camere, precum si in vile si case din Brasov si din zonele limitrofe.
                    </p>


                    <h3>Curatenie la domiciliu?
                        <span>Intotdeauna cu placere!</span></h3>
                    <p>Stim ca muncesti din greu intreaga saptamana, asa ca odata ce weekend-ul se apropie, ultimul
                        lucru pe care ti-ai dori sa-l faci este, cu siguranta, curatenia in intreaga casa.
                        Echipa noastra este pregatita sa se deplaseze oriunde in Brasov si in zonele limitrofe pentru a-ti lua
                        de pe umeri sarcinile casnice sau chiar sentimentul de vinovatie pe care il ai atunci cand stii
                        ca ar trebui sa cureti casa, dar nu o faci.
                    </p>
                    <h3>Ce facem pentru casa ta?
                    </h3>
                    <ul>
                        <li>
                            <i class="icofont-check-circled"></i>Aspirare si curatare profesionala mochete si covoare
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>Spalare mecanizata gresie si podele dure</li>
                        <li>
                            <i class="icofont-check-circled"></i>Igienizare baie si bucatarie</li>
                        <li>
                            <i class="icofont-check-circled"></i>Curatare plinta</li>
                        <li>
                            <i class="icofont-check-circled"></i>Indepartarea panzelor de paianjen</li>
                        <li>
                            <i class="icofont-check-circled"></i>Stergerea lustrelor si a lampadarelor</li>
                        <li>
                            <i class="icofont-check-circled"></i>Stergerea tocurilor de geam si a pervazelor</li>
                        <li>
                            <i class="icofont-check-circled"></i>Stergerea caloriferelor si a tevilor</li>
                        <li>
                            <i class="icofont-check-circled"></i>Sanitarizarea faiantei, chiuvetelor si a closetelor
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>Debarasarea gunoiului menajer</li>
                    </ul>

                    <p>
                        <strong>In plus, putem sa mai facem si:
                        </strong>
                    </p>

                    <ul>
                        <li>
                            <i class="icofont-check-circled"></i>Curatare canapele, fotolii, scaune si saltele</li>
                        <li>
                            <i class="icofont-check-circled"></i>Curatenie dupa renovare / constructor</li>
                        <li>
                            <i class="icofont-check-circled"></i>Dezinfectarea spatiilor frecvent utilizate (pardoseli,
                            usi & tocuri, puncte de acces comun)</li>
                        <li>
                            <i class="icofont-check-circled"></i>Dezinfectare prin nebulizare (ceata rece)</li>

                    </ul>
                    <h3>Cum iti vei petrece timpul pe care tocmai l-ai castigat?
                    </h3>

                    <p>Sunt atatea feluri placute de a-ti petrece timpul, incat nu stim de unde sa incepem!
                    </p>

                    <p>Poate o iesire in aer liber cu intreaga familie, un pranz delicios cu prietenele, sport in aer liber, o vizita la prietenii tai dragi, un picnic la iarba verde, ori chiar proiectul acela DIY pe care il amani de atata vreme...Si ia gandeste-te cand a fost ultima data cand pur si simplu ai stat si te-ai relaxat nefacand absolut nimic?
                    </p>

                    <h3>Sa apelezi la o firma de curatenie pentru acasa - intreaga familie iti va multumi!
                    </h3>
                    <p>Iti poti imagina cum o sa fie cand nu vor mai exista discutii de genul “Al cui e randul la curatarea baii?”, “Cine da azi cu mopul in bucatarie?”, “E randul tau sa dai cu aspiratorul!” 
                        Lasa-ne pe noi sa ne facem astfel de griji! Tu gandeste-te doar ce vei face cu timpul liber pe care l-ai redobandit.
                        
                    </p>
                  
                </div>

                
            </div>

            <div class="col-lg-4 main-service-area blog-side-bar">
                <app-sidebar></app-sidebar>
            </div>
        </div>
    </div>
</div>
