<div class="service-title service-title-bg bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h1>FIRMA CURATENIE BRASOV</h1>
                    <ul>
                        <li>
                            <a routerLink="/">Acasa</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Firma curatenie Brasov
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="team-section team-style-four pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <a routerLink="/curatenie-birouri-brasov" class="team-card">
                    <div class="team-img">
                        <img src="assets/img/service/curatanie birouri brasov.jpg" alt="curatanie birouri brasov">
                    </div>
                    <div class="team-text">
                        <h4>CURATENIE BIROURI</h4>
                    </div>
                </a>
            </div> 
            
            <div class="col-lg-4 col-md-6">
                <a routerLink="/curatenie-la-domiciliu-brasov" class="team-card">
                    <div class="team-img">
                        <img src="assets/img/service/curatenie la domiciliu brasov.jpg" alt="curatenie la domiciliu brasov">
                    </div>
                    <div class="team-text">
                        <h4>CURATENIE LA DOMICILIU</h4>
                    </div>
                </a>
            </div> 
            <div class="col-lg-4 col-md-6">
                <a routerLink="/curatenie-scari-de-bloc-brasov" class="team-card">
                    <div class="team-img">
                        <img src="assets/img/service/curatenie scari de-bloc brasov.jpg" alt="curatenie scari de-bloc brasov">
                    </div>
                    <div class="team-text">
                        <h4>CURATENIE SCARI DE BLOC / ASOCIATII DE LOCATARI</h4>
                    </div>
                </a>
            </div> 
            <div class="col-lg-4 col-md-6">
                <a routerLink="/curatenie-restaurante-cafenele-baruri-brasov" class="team-card">
                    <div class="team-img">
                        <img src="assets/img/service/curatenie horeca brasov.jpg" alt="curatenie horeca brasov">
                    </div>
                    <div class="team-text">
                        <h4>CURATENIE HOTELURI, RESTAURANTE, CAFENELE</h4>
                    </div>
                </a>
            </div> 
            <div class="col-lg-4 col-md-6">
                <a routerLink="/curatenie-dupa-constructor-renovare-brasov" class="team-card">
                    <div class="team-img">
                        <img src="assets/img/service/curatenie dupa constructor brasov.jpg" alt="curatenie dupa constructor brasov">
                    </div>
                    <div class="team-text">
                        <h4>CURATENIE DUPA RENOVARE / CONSTRUCTOR</h4>
                    </div>
                </a>
            </div> 
            <div class="col-lg-4 col-md-6">
                <a routerLink="/curatenie-saloane-infrumusetare-cabinete-medicale-brasov" class="team-card">
                    <div class="team-img">
                        <img src="assets/img/service/curatenie cabinete medicale saloane infrumusetare.jpg" alt="curatenie cabinete medicale saloane infrumusetare brasov">
                    </div>
                    <div class="team-text">
                        <h4>CURATENIE SALOANE BEAUTY SI CABINETE MEDICALE</h4>
                    </div>
                </a>
            </div> 
        </div>
    </div>
</div>

<app-biocid-section></app-biocid-section>
<app-portfolio-slider></app-portfolio-slider>
<app-testimonial></app-testimonial>
<app-logo-section></app-logo-section>