<div class="service-title service-title-bg bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h2>Our Services Two</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-rounded-double-right"></i> Services Two</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="service-section pt-100 pb-70">
    <div class="container">
        <div class="section-head text-center">
            <h2>Viscous Provided <span>Services</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <i class="flaticon-award"></i>
                    <h3>Top Rated Service</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contract.</p>
                    <div class="theme-button">
                        <a routerLink="/service-details" class="default-btn">Read More</a>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="service-card active-service">
                    <i class="flaticon-credit-card"></i>
                    <h3>Low Cost Service</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contract.</p>
                    <div class="theme-button">
                        <a routerLink="/service-details" class="default-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <i class="flaticon-clock"></i>
                    <h3>Fastest Service</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contract.</p>
                    <div class="theme-button">
                        <a routerLink="/service-details" class="default-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <i class="flaticon-cleaning-spray"></i>
                    <h3>Professional Cleaning</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contract.</p>
                    <div class="theme-button">
                        <a routerLink="/service-details" class="default-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <i class="flaticon-clean-house"></i>
                    <h3>House Cleaning</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contract.</p>
                    <div class="theme-button">
                        <a routerLink="/service-details" class="default-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <i class="flaticon-wiper"></i>
                    <h3>Office Cleaning</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contract.</p>
                    <div class="theme-button">
                        <a routerLink="/service-details" class="default-btn">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>