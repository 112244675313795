<div class="service-title service-title-bg bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h2>Our Services Three</h2>
                    <ul>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Services Three
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="main-service-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 main-service-area blog-side-bar">
                <div class="service-sidebar">
                    <form class="search-form">
                        <div class="form-group">              
                            <input type="text" class="form-control" placeholder="Search">
                            <button type="submit" class="btn btn-primary">
                                <i class="icofont-search-1"></i>
                            </button>
                        </div>
                    </form>
                    
                    <div class="service-list">
                        <p>Categories</p>                            
                        <ul>
                            <li>
                                <a href="#">
                                    Toilet Cleaning 
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    Stay Hygienic 
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    News
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    Cleaning Floor
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    Cleaning Kitchen
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    Wash Home
                                    <i class="icofont-rounded-right"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="discount-text">
                        <h1>Get <span>15% Discount</span> on your first Service</h1>

                        <div class="theme-button">
                            <a href="#" class="default-btn">Book Now</a>
                        </div>

                        <div class="discount-shapes">
                            <img src="assets/img/counter/1.png" alt="shape">
                            <img src="assets/img/counter/2.png" alt="shape">
                            <img src="assets/img/counter/3.png" alt="shape">
                            <img src="assets/img/counter/4.png" alt="shape">
                            <img src="assets/img/counter/5.png" alt="shape">
                            <img src="assets/img/counter/6.png" alt="shape">
                            <img src="assets/img/counter/7.png" alt="shape">
                            <img src="assets/img/counter/8.png" alt="shape">
                            <img src="assets/img/counter/5.png" alt="shape">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-8">
                <div class="row">
                    <div class="col-md-6">
                        <div class="service-item">
                            <i class="flaticon-award"></i>
                            <h3>Top-Rated Service</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc.</p>
    
                            <div class="theme-button">
                                <a routerLink="/service-details" class="default-btn">Read More</a>
                            </div>
    
                            <div class="service-shape">
                                <img src="assets/img/service/shapes/service-pattern-6.png" alt="shape">
                                <img src="assets/img/service/shapes/service-pattern-7.png" alt="shape">
                                <img src="assets/img/service/shapes/service-pattern-8.png" alt="shape">
                                <img src="assets/img/service/shapes/service-pattern-9.png" alt="shape">
                                <img src="assets/img/service/shapes/service-pattern-10.png" alt="shape">
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="service-item">
                            <i class="flaticon-credit-card"></i>
                            <h3>Fastest Service</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc.</p>
    
                            <div class="theme-button">
                                <a routerLink="/service-details" class="default-btn">Read More</a>
                            </div>
    
                            <div class="service-shape">
                                <img src="assets/img/service/shapes/service-pattern-6.png" alt="shape">
                                <img src="assets/img/service/shapes/service-pattern-7.png" alt="shape">
                                <img src="assets/img/service/shapes/service-pattern-8.png" alt="shape">
                                <img src="assets/img/service/shapes/service-pattern-9.png" alt="shape">
                                <img src="assets/img/service/shapes/service-pattern-10.png" alt="shape">
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="service-item">
                            <i class="flaticon-clean-house"></i>
                            <h3>House Cleaning</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc.</p>
    
                            <div class="theme-button">
                                <a routerLink="/service-details" class="default-btn">Read More</a>
                            </div>
    
                            <div class="service-shape">
                                <img src="assets/img/service/shapes/service-pattern-6.png" alt="shape">
                                <img src="assets/img/service/shapes/service-pattern-7.png" alt="shape">
                                <img src="assets/img/service/shapes/service-pattern-8.png" alt="shape">
                                <img src="assets/img/service/shapes/service-pattern-9.png" alt="shape">
                                <img src="assets/img/service/shapes/service-pattern-10.png" alt="shape">
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="service-item">
                            <i class="flaticon-cleaning-spray"></i>
                            <h3>Professional Cleaning</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc.</p>
    
                            <div class="theme-button">
                                <a routerLink="/service-details" class="default-btn">Read More</a>
                            </div>
    
                            <div class="service-shape">
                                <img src="assets/img/service/shapes/service-pattern-6.png" alt="shape">
                                <img src="assets/img/service/shapes/service-pattern-7.png" alt="shape">
                                <img src="assets/img/service/shapes/service-pattern-8.png" alt="shape">
                                <img src="assets/img/service/shapes/service-pattern-9.png" alt="shape">
                                <img src="assets/img/service/shapes/service-pattern-10.png" alt="shape">
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="service-item">
                            <i class="flaticon-wiper"></i>
                            <h3>Office Cleaning</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc.</p>
    
                            <div class="theme-button">
                                <a routerLink="/service-details" class="default-btn">Read More</a>
                            </div>
    
                            <div class="service-shape">
                                <img src="assets/img/service/shapes/service-pattern-6.png" alt="shape">
                                <img src="assets/img/service/shapes/service-pattern-7.png" alt="shape">
                                <img src="assets/img/service/shapes/service-pattern-8.png" alt="shape">
                                <img src="assets/img/service/shapes/service-pattern-9.png" alt="shape">
                                <img src="assets/img/service/shapes/service-pattern-10.png" alt="shape">
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="service-item">
                            <i class="flaticon-clean-house"></i>
                            <h3>Low Cost Service</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc.</p>
    
                            <div class="theme-button">
                                <a routerLink="/service-details" class="default-btn">Read More</a>
                            </div>
    
                            <div class="service-shape">
                                <img src="assets/img/service/shapes/service-pattern-6.png" alt="shape">
                                <img src="assets/img/service/shapes/service-pattern-7.png" alt="shape">
                                <img src="assets/img/service/shapes/service-pattern-8.png" alt="shape">
                                <img src="assets/img/service/shapes/service-pattern-9.png" alt="shape">
                                <img src="assets/img/service/shapes/service-pattern-10.png" alt="shape">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>