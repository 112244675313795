<div class="testimonial-section testimonial-bg ptb-100">
    <div class="testimonial-area">
        <div class="container">
            <div class="testimonial-slider owl-carousel owl-theme text-center">
                <div class="sin-testiText">
                    <h2>Marius, GOURMET</h2>
                    <span>Servicii de top și personal pe măsură ! Tot respectul!❤️Recomand cu încredere!</span>
                </div>
                <div class="sin-testiText">
                    <h2>Alina, LUADO CHOCOLATE</h2>
                    <span>Partenerul nostru În materie de curățenie. Profesionalism și atenție la detalii. Recomandăm cu drag!</span>
                </div>
                <div class="sin-testiText">
                    <h2>Alina Stefania</h2>
                    <span>Mulțumesc, AllSteam! Promptitudine, seriozitate, profesionalism! Servicii de igienizare și dezinfecție la superlativ!</span>
                </div>
                <div class="sin-testiText">
                    <h2>Pensiunea Piatra Craiului</h2>
                    <span>Profesioniști în dezinfecția spațiilor expuse prin nebulizare cu dezinfectant.</span>
                </div>
                <div class="sin-testiText">
                    <h2>Pensiunea Piatra Craiului</h2>
                    <span>Profesioniști în dezinfecția spațiilor expuse prin nebulizare cu dezinfectant.</span>
                </div>
                <div class="sin-testiText">
                    <h2>Carmen, AFTER SCHOOL Fun House</h2>
                    <span>Mulțumim Allsteam pentru serviciile de dezinfecție și igienizare cu abur, cu siguranță vom apela și pe viitor!</span>
                </div>

            </div>
        </div>
    </div>
</div>