<div class="service-title service-title-bg bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h1>CURATARE CANAPELE, FOTOLII, SALTELE</h1>
                    <ul>
                        <li>
                            <a routerLink="/">Acasa</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Servicii de curatare
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                           Curatare canapele, fotolii, saltele
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-details-area main-service-area pt-100 services-details-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <img src="assets/img/cleaning/curatare canapele brasov.jpg"
                    alt="curatare canapele brasov">
                <div class="service-details-post">
                    
                    <p>Canapeaua, fotoliile sau scaunele tapitate sunt obiecte de mobilier cu rol estetic si functional
                        in orice spatiu, fie ca vorbim despre o locuinta personala, un salon de evenimente, un birou sau
                        sala de asteptare din saloanele de beauty si cabinetele medicale.
                        Totodata, acestea reprezinta si spatiile care aduna cel mai mult praf, acarieni, murdarie, sau
                        chiar virusuri si bacterii daunatoare sanatatii noastre, asa ca necesita o curatare profesionala
                        frecventa.
                    </p>


                    <h3>Curatare canapele, fotolii si scaune tapitate</h3>
                    <p>Timpul si utilizarea frecventa isi pun amprenta pe tapiteria acestor elemente de mobilier,
                        estompandu-le culorile vibrante de la inceputuri. Lasa-ne sa le readucem frumusetea!
                        In plus, stergem urmele micilor accidente care provoaca amintiri nedorite, precum vin rosu,
                        cafea, pix sau ciocolata.
                    </p>
                    <p style="font-size: 22px !important;">Curatam in profunzime, nu doar la suprafata, folosind
                        aspiratorul ultraperformant de injectie-extractie</p>
                    <p>Poate ca suna complicat, dar procedeul este pe cat de simplu, pe atat de eficient! Folosind o
                        solutie speciala de apa cu detergent profesional introducem in interiorul materialului, lasand-o
                        sa actioneze. Apoi o extragem cu precizie, nu doar solutia, ci si toata murdaria, bacteriile si
                        mirosurile neplacute pe care o aceasta le elimina.
                        Partea frumoasa este ca dureaza mai putin de 5 ore pentru a te bucura de o canapea ca noua!
                    </p>
                    <h3>De ce sa ne lasi pe noi sa curatam canapelele?</h3>

                    <ul>
                        <li>
                            <i class="icofont-check-circled"></i>Pentru noi e este mult mai simplu - avem la dispozitie
                            solutii de curatare si aparatura de ultima tehnologie</li>
                        <li>
                            <i class="icofont-check-circled"></i>Nu este deloc costisitor, poate fi mai scump daca o
                            strici cu solutii de curatare nepotrivite</li>
                        <li>
                            <i class="icofont-check-circled"></i>Pentru ca experienta ne-a invatat cum sa scoatem in
                            siguranta petele de pe orice tip de material</li>
                        <li>
                            <i class="icofont-check-circled"></i>Noi facem asta rapid, scoatem murdaria din profunzime
                            si-ti lasam o canapea curata, igienica si aproape uscata</li>

                    </ul>
                    <h2>Curatare saltele la domiciliu</h2>
                    <p>Ne petrecem mai bine de 8 ore in fiecare zi, dormind. Stiai ca in timp, saltelele devin un mediu
                        propice pentru dezvoltarea diferitelor tipuri de acarieni, bacterii si alti alergeni? Este
                        alegerea ta sa dormi intr-un mediu curat si sanatos!
                    </p>
                    <h3>Procesul de curatare a saltelelor se realizeaza in 3 pasi:</h3>
                    <ol>

                        <li>Curatarea de suprafata a petelor si eliminarea prafului</li>

                        <li>Curatarea in profunzime pentru distrugerea acarienilor si a agentilor alergeni</li>

                        <li>Igienizarea cu abur fierbinte pentru eliminarea completa a bacteriilor</li>


                    </ol>
                    
                    <p>
                        Nu vei dormi niciodata mai bine ca atunci cand stii ca ai grija de sanatatea ta si a familiei tale!
                    </p>

                   
                </div>

              
            </div>

            <div class="col-lg-4 main-service-area blog-side-bar">
                <app-sidebar></app-sidebar>
            </div>
        </div>
    </div>
</div>
