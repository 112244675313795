<div class="pricing-title bg-1  service-title service-title-bg bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <h2>Preturi</h2>

            <ul>
                <li>
                    <a routerLink="/">Acasa</a>
                </li>
                <li>
                    <i class="icofont-rounded-double-right"></i>
                    Preturi
                </li>
            </ul>
        </div>
    </div>
</div>

<section class="pricing-section pt-100 pb-70">
    <div class="container">
        <div class="section-head text-center">
            <h2>Lista noastra de <span>preturi</span></h2>
            <p>Preturile pentru serviciile de curatenie sunt orientative. Ele variaza in functie de complexitatea si
                suprafata spatiului ce urmeaza a fi curatat, de gradul de murdarie si de cerintele suplimentare.

            </p>
            <p>Aceste criterii pot fi stabilite dupa o vizionare a spatiului de catre unul din agentii nostri de
                curatenie.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="price-card-area active-price">
                    <div class="price-head-text">
                        <h4>CURATENIE LA DOMICILIU</h4>
                    </div>

                    <div class="plan-features">
                        <ul>
                            <li>
                                <i class="icofont-check-circled"></i>Garsoniera: 500 RON</li>
                            <li>
                                <i class="icofont-check-circled"></i>Apartament 2 camere: 600 RON</li>
                            <li>
                                <i class="icofont-check-circled"></i>Apartament 3 camere: 700 RON</li>
                            <li>
                                <i class="icofont-check-circled"></i>Apartament 5 camere: 800 RON</li>
                            <li>
                                <i class="icofont-check-circled"></i>Vile: 10 RON / mp</li>
                        </ul>
                    </div>

                    <div class="plan-btn">
                        <a routerLink="/contact">Suna</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="price-card-area active-price">
                    <div class="price-head-text">
                        <h4>IGIENIZARE </h4>

                    </div>

                    <div class="plan-features">
                        <ul>
                            <li>
                                <i class="icofont-check-circled"></i>Cu dezinfectant: 1,2 RON / mp </li>
                            <li>
                                <i class="icofont-check-circled"></i>Cu nebulizare: 3 RON / mp</li>

                        </ul>
                    </div>

                    <div class="plan-btn">
                        <a routerLink="/">Suna</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="price-card-area active-price">
                    <div class="price-head-text">
                        <h4>CURATENIE DUPA CONSTRUCTOR </h4>

                    </div>

                    <div class="plan-features">
                        <ul>
                            <li>
                                <i class="icofont-check-circled"></i>Intre 12 RON si 15 RON / mp*</li>

                            <li>
                                *In functie de gradul de murdarie</li>
                        </ul>
                    </div>

                    <div class="plan-btn">
                        <a routerLink="/">Suna</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="price-card-area active-price">
                    <div class="price-head-text">
                        <h4>CURATENIE GENERALA </h4>

                    </div>

                    <div class="plan-features">
                        <ul>
                            <li>
                                <i class="icofont-check-circled"></i>10 RON / mp</li>
                        </ul>
                    </div>

                    <div class="plan-btn">
                        <a routerLink="/">Suna</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="price-card-area active-price">
                    <div class="price-head-text">
                        <h4>CURATENIE DE INTRETINERE </h4>

                    </div>

                    <div class="plan-features">
                        <ul>
                            <li>
                                <i class="icofont-check-circled"></i>6 RON / mp</li>
                        </ul>
                    </div>

                    <div class="plan-btn">
                        <a routerLink="/">Suna</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="price-card-area active-price">
                    <div class="price-head-text">
                        <h4>INTRETINERE HORECA</h4>

                    </div>

                    <div class="plan-features">
                        <ul>
                            <li>
                                <i class="icofont-check-circled"></i>Bucatarii: 12 RON / mp</li>
                            <li>
                                <i class="icofont-check-circled"></i>Lobby: 10 RON / mp</li>
                        </ul>
                    </div>

                    <div class="plan-btn">
                        <a routerLink="/">Suna</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="price-card-area active-price">
                    <div class="price-head-text">
                        <h4>CURATARE CANAPELE</h4>

                    </div>

                    <div class="plan-features">
                        <ul>
                            <li>
                                <i class="icofont-check-circled"></i>3 locuri: 200 RON</li>
                            <li>
                                <i class="icofont-check-circled"></i>4 locuri: 300 RON</li>
                            <li>
                                <i class="icofont-check-circled"></i>Fotolii: 80 RON</li>
                            <li>
                                <i class="icofont-check-circled"></i>Scaun tapitat: 25 RON</li>


                        </ul>
                    </div>

                    <div class="plan-btn">
                        <a routerLink="/">Suna</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="price-card-area active-price">
                    <div class="price-head-text">
                        <h4>CURATARE MOCHETA</h4>

                    </div>

                    <div class="plan-features">
                        <ul>
                            <li>
                                <i class="icofont-check-circled"></i>12 RON / mp
                            </li>
                        </ul>
                    </div>

                    <div class="plan-btn">
                        <a routerLink="/">Suna</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="price-card-area active-price">
                    <div class="price-head-text">
                        <h4>CURATARE AUTO</h4>

                    </div>

                    <div class="plan-features">
                        <ul>
                            <li>
                                <i class="icofont-check-circled"></i>Intre 450 RON si 600 RON </li>
                            <li>
                                <i class="icofont-check-circled"></i>Cabina TIR: 500 RON</li>
                            <li>
                                <i class="icofont-check-circled"></i>AUTOBUZ: 800 RON</li>
                            <li>
                                <i class="icofont-check-circled"></i>RULOTA: 500 RON</li>
                            <li>
                                <i class="icofont-check-circled"></i>AVION: 3000 RON</li>
                        </ul>
                    </div>

                    <div class="plan-btn">
                        <a routerLink="/">Suna</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="price-card-area active-price">
                    <div class="price-head-text">
                        <h4>CURATAT ECHIPAMENTE HORECA</h4>

                    </div>

                    <div class="plan-features">
                        <ul>
                            <li>
                                <i class="icofont-check-circled"></i>Intre 150 - 250 RON / buc
                            </li>
                        </ul>
                    </div>

                    <div class="plan-btn">
                        <a routerLink="/">Suna</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="price-card-area active-price">
                    <div class="price-head-text">
                        <h4>CURATAT HOTE PROFESIONALE</h4>

                    </div>

                    <div class="plan-features">
                        <ul>
                            <li>
                                <i class="icofont-check-circled"></i>Intre 400 - 800 RON / BUC
                            </li>
                        </ul>
                    </div>

                    <div class="plan-btn">
                        <a routerLink="/">Suna</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="price-card-area active-price">
                    <div class="price-head-text">
                        <h4>CURATAT MOTOR HOTA</h4>

                    </div>

                    <div class="plan-features">
                        <ul>
                            <li>
                                <i class="icofont-check-circled"></i>Intre 600 - 900 RON / buc
                            </li>
                        </ul>
                    </div>

                    <div class="plan-btn">
                        <a routerLink="/">Suna</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="price-card-area active-price">
                    <div class="price-head-text">
                        <h4>CURATAT TUBULATURA</h4>

                    </div>

                    <div class="plan-features">
                        <ul>
                            <li>
                                <i class="icofont-check-circled"></i>Intre 150 - 300 RON
                            </li>
                        </ul>
                    </div>

                    <div class="plan-btn">
                        <a routerLink="/">Suna</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
