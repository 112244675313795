<div class="blog-title blog-title-bg">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="blog-title-text">
                    <h2>Our Blogs</h2>
                    <ul>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Blogs
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-post-top pt-100">
    <div class="container">
        <div class="row pb-65 align-items-center">
            <div class="col-lg-4 col-md-4">
                <h2>Our Latest <span>Blogs</span></h2>
            </div>
            <div class="col-lg-4 col-md-4 offset-lg-1">
                <div class="search-bar">
                    <form>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Search|">
                        </div>
                    </form>
                    <button>
                        <i class="icofont-search-1"></i>
                    </button>
                </div>
            </div>
            <div class="col-lg-3 col-md-4">
                <div class="categories text-right">
                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Categories
                            <i class="icofont-caret-down"></i>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="#">Home Clinging</a>
                            <a class="dropdown-item" href="#">Office Clinging</a>
                            <a class="dropdown-item" href="#">Car Clinging</a>
                            <a class="dropdown-item" href="#">Store Clinging</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="main-blog-post">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="blog-area">
                    <div class="blog-img">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/1.png" alt="blog image">
                        </a>
                    </div>
                    <div class="blog-text">
                        <h4><a routerLink="/blog-details">How to cleanroom yourself</a></h4>
                        <ul>
                            <li>
                                <i class="icofont-calendar"></i>
                                20 Feb 2020
                            </li>
                            <li>
                                <i class="icofont-ui-user"></i>
                                <a href="#">John Doe</a>
                            </li>
                        </ul>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua, sed do eiusmod.</p>

                        <a routerLink="/blog-details" class="blog-btn">
                            Read more
                            <i class="icofont-rounded-right"></i>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-area">
                    <div class="blog-img">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/2.png" alt="blog image">
                        </a>
                    </div>
                    <div class="blog-text">
                        <h4><a routerLink="/blog-details">Enjoy the working time</a></h4>
                        <ul>
                            <li>
                                <i class="icofont-calendar"></i>
                                20 Feb 2020
                            </li>
                            <li>
                                <i class="icofont-ui-user"></i>
                                <a href="#">John Doe</a>
                            </li>
                        </ul>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua, sed do eiusmod.</p>

                        <a routerLink="/blog-details" class="blog-btn">
                            Read more
                            <i class="icofont-rounded-right"></i>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-area">
                    <div class="blog-img">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/3.png" alt="blog image">
                        </a>
                    </div>
                    <div class="blog-text">
                        <h4><a routerLink="/blog-details">Self cleaning is the best drill</a></h4>
                        <ul>
                            <li>
                                <i class="icofont-calendar"></i>
                                20 Feb 2020
                            </li>
                            <li>
                                <i class="icofont-ui-user"></i>
                                <a href="#">John Doe</a>
                            </li>
                        </ul>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua, sed do eiusmod.</p>

                        <a routerLink="/blog-details" class="blog-btn">
                            Read more
                            <i class="icofont-rounded-right"></i>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-area">
                    <div class="blog-img">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/4.png" alt="blog image">
                        </a>
                    </div>
                    <div class="blog-text">
                        <h4><a routerLink="/blog-details">How to clean your office</a></h4>
                        <ul>
                            <li>
                                <i class="icofont-calendar"></i>
                                20 Feb 2020
                            </li>
                            <li>
                                <i class="icofont-ui-user"></i>
                                <a href="#">John Doe</a>
                            </li>
                        </ul>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua, sed do eiusmod.</p>

                        <a routerLink="/blog-details" class="blog-btn">
                            Read more
                            <i class="icofont-rounded-right"></i>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-area">
                    <div class="blog-img">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/5.png" alt="blog image">
                        </a>
                    </div>
                    <div class="blog-text">
                        <h4><a routerLink="/blog-details">Glass cleaning tips</a></h4>
                        <ul>
                            <li>
                                <i class="icofont-calendar"></i>
                                20 Feb 2020
                            </li>
                            <li>
                                <i class="icofont-ui-user"></i>
                                <a href="#">John Doe</a>
                            </li>
                        </ul>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua, sed do eiusmod.</p>

                        <a routerLink="/blog-details" class="blog-btn">
                            Read more
                            <i class="icofont-rounded-right"></i>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-area">
                    <div class="blog-img">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/6.png" alt="blog image">
                        </a>
                    </div>
                    <div class="blog-text">
                        <h4><a routerLink="/blog-details">How to clean your home</a></h4>
                        <ul>
                            <li>
                                <i class="icofont-calendar"></i>
                                20 Feb 2020
                            </li>
                            <li>
                                <i class="icofont-ui-user"></i>
                                <a href="#">John Doe</a>
                            </li>
                        </ul>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua, sed do eiusmod.</p>

                        <a routerLink="/blog-details" class="blog-btn">
                            Read more
                            <i class="icofont-rounded-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <nav>
            <ul class="pagination  justify-content-center">
                <li class="page-item disabled">
                    <a class="page-link" routerLink="/" tabindex="-1" aria-disabled="true">
                        <i class="icofont-rounded-left"></i>
                    </a>
                </li>
                <li class="page-item">
                    <a class="page-link" routerLink="/">1</a>
                </li>
                <li class="page-item" aria-current="page">
                    <a class="page-link active" routerLink="/">2</a>
                </li>
                <li class="page-item">
                    <a class="page-link" routerLink="/">3</a>
                </li>
                <li class="page-item">
                    <a class="page-link" routerLink="/">
                        <i class="icofont-rounded-right"></i>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</div>