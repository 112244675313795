<div class="service-title service-title-bg bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h1>CURATENIE SCARI DE BLOC / ASOCIATII DE LOCATARI</h1>
                    <ul>
                        <li>
                            <a routerLink="/">Acasa</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            <a routerLink="/firma-curatenie-brasov">Servicii de curatenie</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Curatenie scari de bloc / asociatii de locatari
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-details-area main-service-area pt-100 services-details-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <img src="assets/img/service/curatenie scari de-bloc brasov.jpg" alt="curatenie scari de bloc brasov">
                <div class="service-details-post">
                   
                    <p>In contextul actual, este esential sa te simti in siguranta sub aspectul curateniei si a
                        igienizarii, mai ales atunci cand vorbim despre spatii frecvent si intens tranzitate, cum sunt
                        scarile de bloc. Fie ca vorbim de lifturi, fie ca vorbim de balustradele sau scarile dintre
                        paliere, este important ca acestea sa fie frecvent dezinfectate pentru a evita raspandirea
                        bacteriilor si a virusurilor.
                    </p>


                    <h3>Curatenie scari de bloc la
                        <span>standarde inalte de calitate</span></h3>
                    <p>Ca locatar sau ca simplu vizitator al unui bloc, sa te simti confortabil si sa fii in siguranta
                        sunt aspecte care nu pot fi puse sub semnul indoielii.
                        De aceea, echipa noastra vine in sprijinul asociatiilor de proprietari cu a gama diversa de
                        servicii profesionale de curatenie, dezinfectare si igienizare, la preturi accesibile.
                        Echipa noastra de curatenie se ocupa de intreg procesul de curatare, pentru a impiedica
                        patrunderea si raspandirea virusurilor, a bacteriilor si a altor agenti patogeni de la exterior
                        catre interiorul apartamentelor locatarilor.

                    </p>
                    <h3>Ce servicii de curatenie va oferim pentru intretinerea blocurilor?
                    </h3>

                    <ul>
                        <li>
                            <i class="icofont-check-circled"></i>Maturam si spalam scara integral</li>
                        <li>
                            <i class="icofont-check-circled"></i>Curatam si igienizam ascensorul</li>
                        <li>
                            <i class="icofont-check-circled"></i>Stergem usa si geamurile de la intrare</li>
                        <li>
                            <i class="icofont-check-circled"></i>Curatam si dezinfectam balustradele</li>
                        <li>
                            <i class="icofont-check-circled"></i>Maturam aleile de la intrarea in imobil</li>
                        <li>
                            <i class="icofont-check-circled"></i>Stergem praful de pe cutiile postale</li>
                        <li>
                            <i class="icofont-check-circled"></i>Scoatem pubelele de gunoi in zilele in care gunoiul se
                            ridica</li>
                        <li>
                            <i class="icofont-check-circled"></i>Spalam si igienizam pubelele de gunoi</li>
                        <li>
                            <i class="icofont-check-circled"></i>Maturam aleile de acces in imobil si spatiul din jurul
                            blocului</li>
                        <li>
                            <i class="icofont-check-circled"></i>Indepartam panze de paianjen</li>
                        <li>
                            <i class="icofont-check-circled"></i>Deszapezim aleile de acces in sezonul rece</li>
                        <li>
                            <i class="icofont-check-circled"></i>Odorizam scarile</li>

                       

                    </ul>
                    <h3>Conditii flexibile de curatenie scari de bloc in Brasov
                    </h3>

                    <p>Serviciile noastre de curatenie sunt rapide, dar eficiente. Folosim doar produse profesionale, dovedite ca lupta in eliminarea celor mai periculosi agenti patogeni. 
                        Facem curatenie profesionala atat in interiorul, cat si in exteriorul blocului, in intervale de timp stabilite de comun acord. 
                        
                    </p>

                    <p>Este important sa stiti faptul ca produsele noastre de curatenie nu afecteaza sanatatea oamenilor si a animalelor de companie.
                        Apelati cu increredere la echipa noastra de curatenie profesionala scari de bloc din Brasov, pentru un bloc ingrijit, perfect curat, igienizat si sigur pentru sanatatea locatarilor.
                        
                    </p>
                    
                </div>

            
            </div>

            <div class="col-lg-4 main-service-area blog-side-bar">
                <app-sidebar></app-sidebar>
            </div>
        </div>
    </div>
</div>
