import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sanitization-and-desinfection',
  templateUrl: './sanitization-and-desinfection.component.html',
  styleUrls: ['./sanitization-and-desinfection.component.scss']
})
export class SanitizationAndDesinfectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
