<div class="service-title service-title-bg bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="service-title-text">
                    <h1>CURATENIE DUPA RENOVARE / CONSTRUCTOR</h1>
                    <ul>
                        <li>
                            <a routerLink="/">Acasa</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            <a routerLink="/firma-curatenie-brasov">Servicii de curatenie</a>
                        </li>
                        <li>
                            <i class="icofont-rounded-double-right"></i>
                            Curatare dupa renovare / constructor
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-details-area main-service-area pt-100 services-details-page">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <img src="assets/img/service/curatenie dupa constructor brasov.jpg"
                    alt="curatenie dupa constructor brasov">
                <div class="service-details-post">
                    
                    <p>Dupa o lucrare de renovare este bine sa te pregatesti temeinic, pentru ca mizeria si praful vor
                        ajunge in locuri la care nu te-ai putea gandi vreodata.
                        Tencuiala si pulberea fina isi vor gasi drumul in interiorul si in spatele sertarelor, in
                        interiorul corpurilor de iluminat, pe tocurile si in partea superioara a usilor, peste toti
                        peretii si pe tavan, in masina de spalat vase si in cuptorul cu microunde, in interiorul
                        tesaturii canapelelor… Apoi mai sunt gresia, faianta si parchetul, ferestrele si pervazele, care
                        necesita o curatare serioasa in urma petelor accidentale de vopsea.
                    </p>


                    <h3>Curatenie dupa renovare si
                        <span> ai scapat de stres</span></h3>
                    <p>Desigur, ai putea sa faci tu asta, dar cu ce cost?</p>
                    <p>Curatenia dupa renovare sau post-constructor este una dintre cele mai complexe activitati de
                        acest gen.</p>
                    <p>Este dificil sa ajungi la rezultatul pe care ti-l doresti - un spatiu care sa pastreze doar in
                        amintiri mizeria cauzata de materialele de constructie - in lipsa produselor de curatenie
                        profesionale si a aparaturii performante.</p>
                    <p>Noi facem asta simplu, rapid si in siguranta, pentru ca tu sa te bucuri de un spatiu proaspat
                        renovat, curat si igienizat.</p>
                    <h3>Cu ce te putem ajuta in curatenia de dupa renovare?</h3>

                    <ul>
                        <li>
                            <i class="icofont-check-circled"></i>Stergem definitiv orice urma de materiale de
                            constructie, moloz, pete dificile si alte resturi ramase in urma procesului de constructie /
                            renovare</li>
                        <li>
                            <i class="icofont-check-circled"></i>Indepartam resturile de vopsea, var si ciment de pe
                            tocuri, ferestre, usi si calorifere</li>
                        <li>
                            <i class="icofont-check-circled"></i>Eliminam cu grija etichetele lipite pe geamuri, tocuri,
                            rame si pervaze</li>
                        <li>
                            <i class="icofont-check-circled"></i>Curatam balustradele si tevile</li>
                        <li>
                            <i class="icofont-check-circled"></i>Spalam geamurile accesibile pe ambele parti, tocaria si
                            a pervazurilor aferente pe interior si exterior</li>
                        <li>
                            <i class="icofont-check-circled"></i>Curatam si spalam mecanizat pardoselile dure</li>
                        <li>
                            <i class="icofont-check-circled"></i>Curatam corpurile de iluminat, prizele si
                            intrerupatoarele</li>
                    </ul>
                    <h3>Cine poate apela la serviciul de curatenie post constructor / renovare?</h3>

                    <p>
                        Locurile in care s-au facut amenajari sau renovari la domiciliu, in spatii de birouri, hale
                        industriale sau spatii comerciale necesita un proces de curatenie special astfel incat spatiul
                        respectiv sa ramana apt pentru desfasurarea activitatilor.
                    </p>

                    <p>Daca locuiesti in Brasov sau in zonele din apropiere, echipa noastra se va deplasa care locatia
                        ta cu tot “arsenalul” pe care il detinem. Nu ai nevoie sa cumperi produse de curatenie, pentru
                        ca venim noi cu tot ce este necesar, astfel incat sa lasam in urma un spatiu care sclipeste de
                        curatenie.

                    </p>

                </div>


            </div>
            <div class="col-lg-4 main-service-area blog-side-bar">
                <app-sidebar></app-sidebar>
            </div>

        </div>
    </div>
</div>
