<section class="recent-work-section pt-100">
    <div class="container-fluid p-0">
        <div class="section-head blue-title text-center">
            <h2>Aici <span>am lucrat</span> cu drag si pricepere</h2>
            <p>O poza valoreaza mai mult decat 1000 de cuvinte, nu-i asa?
                In curand vom adauga si poza ta mai jos  :) 
                </p>
        </div>

        <div class="recent-work-slider owl-carousel owl-theme">
            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatare-canapele-brasov-2.jpg" alt="curatare-canapele-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatare-canapele-brasov-2.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatare-canapele-brasov.jpg" alt="curatare-canapele-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatare-canapele-brasov.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-1.jpg" alt="curatare-echipamente-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-1.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-2.jpg" alt="curatare-echipamente-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-2.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-3.jpg" alt="curatare-echipamente-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-3.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-4.jpg" alt="curatare-echipamente-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-4.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-5.jpg" alt="curatare-echipamente-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-5.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-6.jpg" alt="curatare-echipamente-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-6.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-7.jpg" alt="curatare-echipamente-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-7.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-8.jpg" alt="curatare-echipamente-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-8.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-9.jpg" alt="curatare-echipamente-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-9.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-10.jpg" alt="curatare-echipamente-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-10.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-11.jpg" alt="curatare-echipamente-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-11.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-12.jpg" alt="curatare-echipamente-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-12.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-13.jpg" alt="curatare-echipamente-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-13.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-14.jpg" alt="curatare-echipamente-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-14.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-15.jpg" alt="curatare-echipamente-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-15.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-16.jpg" alt="curatare-echipamente-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-16.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-17.jpg" alt="curatare-echipamente-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-17.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-18.jpg" alt="curatare-echipamente-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-18.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-19.jpg" alt="curatare-echipamente-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-19.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-20.jpg" alt="curatare-echipamente-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-20.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-21.jpg" alt="curatare-echipamente-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-21.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-22.jpg" alt="curatare-echipamente-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatare-echipamente-horeca-brasov-22.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/CURATARE-TAPITERIE-AUTO-BRASOV-2.jpg" alt="CURATARE-TAPITERIE-AUTO-BRASOV">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/CURATARE-TAPITERIE-AUTO-BRASOV-2.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/CURATARE-TAPITERIE-AUTO-BRASOV.jpg" alt="CURATARE-TAPITERIE-AUTO-BRASOV">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/CURATARE-TAPITERIE-AUTO-BRASOV.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatenie-horeca-brasov-1.jpg" alt="curatenie-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatenie-horeca-brasov-1.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatenie-horeca-brasov-2.jpg" alt="curatenie-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatenie-horeca-brasov-2.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatenie-horeca-brasov-3.jpg" alt="curatenie-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatenie-horeca-brasov-3.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatenie-horeca-brasov-4.jpg" alt="curatenie-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatenie-horeca-brasov-1.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatenie-horeca-brasov-5.jpg" alt="curatenie-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatenie-horeca-brasov-5.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatenie-horeca-brasov-6.jpg" alt="curatenie-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatenie-horeca-brasov-6.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatenie-horeca-brasov-7.jpg" alt="curatenie-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatenie-horeca-brasov-7.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatenie-horeca-brasov-8.jpg" alt="curatenie-horeca-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatenie-horeca-brasov-8.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatenie-la-domiciliu-brasov-1.jpg" alt="curatenie-la-domiciliu-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatenie-la-domiciliu-brasov-1.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatenie-la-domiciliu-brasov-2.jpg" alt="curatenie-la-domiciliu-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatenie-la-domiciliu-brasov-2.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatenie-la-domiciliu-brasov-3.jpg" alt="curatenie-la-domiciliu-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatenie-la-domiciliu-brasov-3.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/curatenie-la-domiciliu-brasov-4.jpg" alt="curatenie-la-domiciliu-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/curatenie-la-domiciliu-brasov-4.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/dezinfectie-igienizare-brasov.jpg" alt="dezinfectie-igienizare-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/dezinfectie-igienizare-brasov.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="recent-items">
                <div class="recent-img">
                    <img src="assets/img/home-portfolio/dezinfectie-igienizare-nebulizare-brasov-2.jpg" alt="dezinfectie-igienizare-nebulizare-brasov">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/home-portfolio/dezinfectie-igienizare-nebulizare-brasov-2.jpg" class="popup-gallery d-block"><i
                                        class="icofont-eye-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</section>
