<div class="contact-section">
    <div class="container">
        <div class="contact-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="contact-img">
                        <img src="assets/img/firma-de-curatenie-brasov.jpg" alt="contact image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="contact-text">
                        <div class="section-head">
                            <h2>Pune-ne
                                <span> la treaba!</span></h2>
                            <p>Completeaza formularul de la jos sau ne poti suna chiar acum.
                            </p>
                        </div>

                        <app-contact-form></app-contact-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/logo2-white.png" alt="logo">
                        </a>
                    </div>
                    <p>Noi facem curatenie, tu te bucuri de timpul castigat. Venim la tine oriunde in Brasov si in zona
                        limitrofa.</p>

                        <div class="anpc">
                            <a
                              href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO"
                              target="_blank"
                            >
                              <img src="/assets/img/anpc1.jpeg" alt="" />
                            </a>
                            <a href="https://anpc.ro/ce-este-sal/" target="_blank">
                              <img src="/assets/img/anpc2sal.jpeg" alt="" />
                            </a>
                          </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget pl-40">
                    <h3>Servicii</h3>

                    <ul>

                        <li><i class="icofont-simple-right"></i> <a routerLink="/firma-curatenie-brasov">Servicii de
                                curatenie</a></li>
                        <li><i class="icofont-simple-right"></i> <a>Servicii de
                                curatare</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/igienizare-dezinfectie-spatii-brasov">Igienizare si
                                dezinfectie</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/preturi-servicii-curatenie-brasov">Preturi</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/contact">Contact</a></li>


                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget pl-40">
                    <h3>Utile</h3>

                    <ul>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/index">Termeni si conditii</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/index">Setari GDPR</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/index">Confidentialitate</a></li>
                        <li><i class="icofont-simple-right"></i> <a routerLink="/index">Politica cookies</a></li>

                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <h3>Contact</h3>
                    <p class="find-text">Suntem mereu disponibili pentru curatenie</p>

                    <ul class="find-us">
                        <li>
                            <i class="icofont-location-pin"></i> Brasov City
                        </li>
                        <li>
                            <i class="icofont-phone"></i>
                            <a href="tel:+40758116325">0758-116-325</a>
                        </li>
                        <li>
                            <i class="icofont-ui-message"></i>
                            <a href="mailto:curatenie@allsteam.ro">curatenie@allsteam.ro</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="bottom-footer">
        <div class="container">
            <div class="row  align-items-center">
                <div class="col-lg-6">
                    <div class="footer-social">
                        <ul>
                            <li><a target="_blank" href="https://www.facebook.com/AllSteam01/"><i
                                class="icofont-facebook"></i></a></li>
                    <li><a target="_blank" href="https://www.instagram.com/allsteam9/"><i
                                class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="copyright-text text-right">
                        <p>&copy;{{year}} All Steam. All Rights Reserved By <a href="https://terra-agency.ro/"
                                target="_blank">Terra Agency</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="top-btn">
    <i class="icofont-scroll-long-up"></i>
</div>
<app-whatsapp></app-whatsapp>